import React, { useState, useEffect } from 'react';
import { FormDataGetService, FormSaveService } from '../../../../domain';
import { AuthContext } from '../../../../adapter/context';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import { ToCurrencyFormat } from '../../../../utils';
import { useNavigate } from 'react-router-dom';

const Form = styled.form`
    opacity: ${(props) => (props.loading ? '0.4' : '1')};
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
`;

const SpinnerWrapper = styled.div`
    display: ${(props) => (props.loading ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`;

export default function ProcurementForm() {
    const navigate = useNavigate();
    const { user, updateUser } = AuthContext();
    const [data, setData] = useState({});
    const [items, setItems] = useState([]);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [loading, setIsLoading] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [formData, setFormData] = useState({
        vendor: '',
        referenceNo: '',
        formDescription: '',
        newItem: {
            description: '',
            quantity: '',
            unit: '',
            unitPrice: '',
            costCenter: '',
            approverId: '',
            branchDesc: ''
        }
    });

    const _formDataGet = new FormDataGetService();
    const _formDataSubmit = new FormSaveService();

    const requestedData = async () => {
        try {
            setIsLoading(true);
            const response = await _formDataGet.RequestData({ size: 50, page: 1, search: '' });
            console.log('request data response: ', response);

            setData(response);
            setIsLoading(false);
        } catch (error) {
            console.log('Error', error);
            alert('Something went wrong...');
        }
    };

    // Form Items Adding
    const handleAddItem = () => {
        const { description, quantity, unit, unitPrice, costCenter } = formData.newItem;

        if (description && quantity && unit && unitPrice && costCenter) {
            const selectedBranch = data.branches.filter(
                (item) => (item?.BRANCH_ID || item?.SUB_BRANCH_ID) == costCenter
            );

            if (!data.branches.length || !selectedBranch.length) {
                alert('Selected branch is Invalid.');
                return;
            }
            const branchDesc = selectedBranch[0].PCC + ' - ' + selectedBranch[0].BRANCH_NAME;
            if (branchDesc === '') branchDesc = 'notFound';
            // console.log('branchDesc', branchDesc);

            const approverId = data.approvers.filter(
                (item) => (item?.branch_id || item?.sub_branch_id) == costCenter
            )[0]?.user_id;

            if (!approverId) {
                alert('Selected branch does not have Existing Approver.');
                return;
            }
            // console.log('approverId', approverId);

            const grossAmount = parseFloat(quantity) * parseFloat(unitPrice);

            setItems([
                ...items,
                {
                    description,
                    quantity: parseInt(quantity),
                    unit,
                    unitPrice: unitPrice,
                    grossAmount: grossAmount,
                    costCenter,
                    approverId,
                    branchDesc
                }
            ]);

            setFormData({
                ...formData,
                newItem: {
                    description: '',
                    quantity: '',
                    unit: '',
                    unitPrice: '',
                    costCenter: '',
                    approverId: '',
                    branchDesc: ''
                }
            });
        } else {
            alert('Please fill the Items to Proceed.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const quantityRegex = /^\d+$/;
        const priceRegex = /^\d*\.?\d{0,2}$/;

        if (name === 'quantity' && (value === '' || (quantityRegex.test(value) && parseInt(value) > 0))) {
            setFormData({
                ...formData,
                newItem: {
                    ...formData.newItem,
                    [name]: value
                }
            });
        }

        if (name === 'unitPrice' && (value === '' || priceRegex.test(value))) {
            setFormData({
                ...formData,
                newItem: {
                    ...formData.newItem,
                    [name]: value
                }
            });
        }

        if (name !== 'quantity' && name !== 'unitPrice') {
            if (['description', 'quantity', 'unit', 'unitPrice', 'costCenter'].includes(name)) {
                setFormData({
                    ...formData,
                    newItem: {
                        ...formData.newItem,
                        [name]: value
                    }
                });
            }
        }
    };

    const editItem = (index) => {
        const editingItem = JSON.parse(JSON.stringify(items[index]));
        setEditingItem(editingItem);
        // console.log('editingItem: ', editingItem);

        setEditingIndex(index);
    };

    const handleEditingItemInputChange = (e, field, index) => {
        const { name, value } = e.target;

        const quantityRegex = /^\d+$/;
        const priceRegex = /^\d*\.?\d{0,2}$/;
        const newItems = [...items];

        if (field === 'quantity' && (value === '' || (quantityRegex.test(value) && parseInt(value) > 0))) {
            newItems[index][field] = e.target.value;
        }

        if (field === 'unitPrice' && (value === '' || priceRegex.test(value))) {
            newItems[index][field] = e.target.value;
            console.log('success');
        } else {
            console.log('failed');
        }

        if (field !== 'quantity' && field !== 'unitPrice') {
            newItems[index][field] = e.target.value;
        }

        newItems[index]['grossAmount'] = newItems[index]['quantity'] * newItems[index]['unitPrice'];

        const selectedBranch = data.branches.filter((item) => item.BRANCH_ID == newItems[index]['costCenter']);

        if (!data.branches.length || !selectedBranch.length) {
            console.log(selectedBranch);
            alert('Selected branch is Invalid.');
            return;
        }
        const branchDesc = selectedBranch[0].PCC + ' - ' + selectedBranch[0].BRANCH_NAME;

        if (branchDesc === '') branchDesc = 'notFound';
        // console.log('branchDesc', branchDesc);
        newItems[index]['branchDesc'] = branchDesc;

        setItems(newItems);
    };

    const applyItemChanges = (index) => {
        setEditingIndex(-1);
        // console.log('Item updated: ', items[index]);
    };

    const cancelItemEditing = async (index) => {
        setIsLoading(true);
        const alertResponse = await Swal.fire({
            title: 'Confirm',
            text: 'Discard edited values?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        });

        if (alertResponse.isConfirmed) {
            items[index] = editingItem;
            setEditingIndex(-1);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    const deleteItem = async (index) => {
        setIsLoading(true);
        const alertResponse = await Swal.fire({
            title: 'Confirm',
            text: 'Delete Item?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        });

        if (alertResponse.isConfirmed) {
            const newItems = items.filter((item, i) => i !== index);
            setItems(newItems);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (editingIndex !== -1) {
            alert('Please commit all changes before saving.');
            return;
        }

        if (!items.length) {
            alert('Please add Items to proceed.');
            return;
        }

        const itemsArray = items.map((item) => ({
            item_description: item.description,
            unit: item.unit,
            quantity: item.quantity,
            unit_price: parseFloat(item.unitPrice).toFixed(2),
            gross_amount: parseFloat(item.grossAmount).toFixed(2),
            vat: '0.00',
            net_amount: '0.00',
            cost_center: item.costCenter,
            created_by: user.id
        }));

        const transactionPayload = {
            data: {
                procurementFormData: {
                    description: formData.formDescription,
                    reference_no: formData.referenceNo,
                    vendor_id: formData.vendor,
                    created_by: user.id,
                    procurementFormItemData: itemsArray
                },
                signature: ''
            }
        };
        console.log('procurement form payload: ', transactionPayload);

        setIsLoading(true);
        const response = await _formDataSubmit.RequestData(transactionPayload);
        console.log('response', response);

        if (!response.data.ok) {
            Swal.fire({
                title: 'Error!',
                text: 'Procurement Save failed. ',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
            return;
        }
        setFormData({
            vendor: '',
            referenceNo: '',
            formDescription: '',
            newItem: {
                description: '',
                quantity: '',
                unit: '',
                unitPrice: '',
                costCenter: '',
                approverId: ''
            }
        });
        setItems([]);
        setIsLoading(false);
        Swal.fire({
            title: 'Success!',
            text: 'Procurement Save Success. ',
            icon: 'success',
            confirmButtonText: 'Ok'
        });
    };

    const clearForm = async () => {
        setIsLoading(true);
        const alertResponse = await Swal.fire({
            title: 'Confirm',
            text: 'Cancel Procurement form creation?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        });

        if (alertResponse.isConfirmed) {
            setFormData({
                vendor: '',
                referenceNo: '',
                formDescription: '',
                newItem: {
                    description: '',
                    quantity: '',
                    unit: '',
                    unitPrice: '',
                    costCenter: '',
                    approverId: '',
                    branchDesc: ''
                }
            });
            setItems([]);
            setEditingIndex(-1);
            setEditingItem(null);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    const goBack = async () => {
        setIsLoading(true);
        const alertResponse = await Swal.fire({
            title: 'Confirm',
            text: 'Cancel Procurement form creation?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        });

        if (alertResponse.isConfirmed) {
            navigate('/app/forms/recent-procurement-request');
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        requestedData();
    }, []);

    return (
        <div>
            <section className="section">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Procurement Details</h5>

                                <SpinnerWrapper loading={loading ? 1 : 0}>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ width: '30px', height: '30px' }}
                                    ></span>
                                </SpinnerWrapper>

                                <Form
                                    onSubmit={handleSubmit}
                                    className="row g-3"
                                    loading={loading ? 1 : 0}
                                    autoComplete="off"
                                    disabled={loading}
                                >
                                    <div className="col-6">
                                        <label htmlFor="vendor" className="form-label">
                                            Vendor
                                        </label>
                                        <select
                                            className="form-select"
                                            name="vendor"
                                            value={formData.vendor}
                                            onChange={(e) => setFormData((val) => ({ ...val, vendor: e.target.value }))}
                                            required
                                        >
                                            <option value="">Please Choose a Vendor</option>
                                            {data.vendors &&
                                                data.vendors.length &&
                                                data.vendors.map((item, index) => {
                                                    return (
                                                        <option
                                                            value={item.VENDOR_ID}
                                                            key={'vendor' + index + item.VENDOR_ID}
                                                        >
                                                            {item.VENDOR_NAME}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="vendor" className="form-label">
                                            Quotation/Reference No.
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="referenceNo"
                                            value={formData.referenceNo}
                                            required
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, referenceNo: e.target.value }))
                                            }
                                        />
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="form_description" className="form-label">
                                            Description
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            name="form_description"
                                            value={formData.formDescription}
                                            required
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, formDescription: e.target.value }))
                                            }
                                        />
                                    </div>

                                    <div className="col-12">
                                        <hr style={{ border: 'dashed 2px black' }} />

                                        <h5 className="card-title my-0 pt-1 pb-3">Procurement Items</h5>

                                        <div className="row">
                                            <div className="col-4">
                                                <label className=" col-form-label">Item Description</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="description"
                                                    value={formData.newItem.description}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-1">
                                                <label htmlFor="quantity" className=" col-form-label">
                                                    Quantity
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="quantity"
                                                    value={formData.newItem.quantity}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-1">
                                                <label className=" col-form-label">Unit</label>
                                                <select
                                                    className="form-control"
                                                    name="unit"
                                                    value={formData.newItem.unit}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Pack">Pack</option>
                                                    <option value="Piece">Piece</option>
                                                    <option value="Pad">Pad</option>
                                                    <option value="Liter">Liter</option>
                                                </select>
                                            </div>
                                            <div className="col-2">
                                                <label className=" col-form-label">Unit Price</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="unitPrice"
                                                    value={formData.newItem.unitPrice}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <label className=" col-form-label">Branch / Cost Center</label>
                                                <select
                                                    className="form-control"
                                                    name="costCenter"
                                                    value={formData.newItem.costCenter}
                                                    onChange={handleInputChange}
                                                >
                                                    <option>Select</option>
                                                    {data.branches &&
                                                        data.branches.length &&
                                                        data.branches.map((item, index) => {
                                                            return (
                                                                <option
                                                                    value={item.BRANCH_ID ?? item.SUB_BRANCH_ID}
                                                                    key={`branches_${index}_${item.BRANCH_ID}`}
                                                                >
                                                                    {item.PCC} - {item.BRANCH_NAME}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                            <div className="col-1">
                                                <label className="col-form-label" style={{ color: 'white' }}>
                                                    .
                                                </label>
                                                <div className="d-grid">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary rounded-pill"
                                                        onClick={() => handleAddItem()}
                                                    >
                                                        <i className="bi bi-plus-lg"> </i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <br />

                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>#</th>
                                                    <th>Description</th>
                                                    <th>Branch / Cost Center</th>
                                                    <th>Quantity</th>
                                                    <th>Unit</th>
                                                    <th>Unit Price</th>
                                                    <th width="10%">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {items.map((item, index) => (
                                                    <tr key={'itemstbl_' + index}>
                                                        <td width="1">
                                                            {editingIndex === index ? (
                                                                <div className="d-flex flex-row">
                                                                    <div className="me-1">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-success rounded-pill btn-sm"
                                                                            onClick={() => applyItemChanges(index)}
                                                                        >
                                                                            <i className="bi bi-check-lg"> </i>
                                                                        </button>
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-warning rounded-pill btn-sm"
                                                                        onClick={() => cancelItemEditing(index)}
                                                                    >
                                                                        <i className="bi bi-x-circle"></i>
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                <div className="d-flex flex-row">
                                                                    <div className="me-1">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-secondary rounded-pill btn-sm"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                editItem(index);
                                                                            }}
                                                                        >
                                                                            <i className="bi bi-pencil-fill"> </i>
                                                                        </button>
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger rounded-pill btn-sm"
                                                                        onClick={() => deleteItem(index)}
                                                                    >
                                                                        <i className="bi bi-trash-fill"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {editingIndex === index ? (
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={item.description}
                                                                    onChange={(e) =>
                                                                        handleEditingItemInputChange(
                                                                            e,
                                                                            'description',
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                item.description
                                                            )}
                                                        </td>
                                                        <td>
                                                            {editingIndex === index ? (
                                                                <select
                                                                    className="form-control"
                                                                    name="costCenter"
                                                                    id="costCenter"
                                                                    value={item.costCenter}
                                                                    onChange={(e) =>
                                                                        handleEditingItemInputChange(
                                                                            e,
                                                                            'costCenter',
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <option value="">Please choose</option>
                                                                    {data.branches &&
                                                                        data.branches.length &&
                                                                        data.branches.map((branch) => (
                                                                            <option
                                                                                value={
                                                                                    item.BRANCH_ID ?? branch.SUB_BRANCH_ID
                                                                                }
                                                                                key={'branch_' + branch.BRANCH_ID}
                                                                            >
                                                                                {branch.PCC} - {branch.BRANCH_NAME}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                            ) : (
                                                                item.branchDesc
                                                            )}
                                                        </td>
                                                        <td width="10%">
                                                            {editingIndex === index ? (
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={item.quantity}
                                                                    onChange={(e) =>
                                                                        handleEditingItemInputChange(
                                                                            e,
                                                                            'quantity',
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                item.quantity
                                                            )}
                                                        </td>
                                                        <td>
                                                            {editingIndex === index ? (
                                                                <select
                                                                    className="form-control"
                                                                    name="unit"
                                                                    id="unit"
                                                                    value={item.unit}
                                                                    onChange={(e) =>
                                                                        handleEditingItemInputChange(e, 'unit', index)
                                                                    }
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option value="Pack">Pack</option>
                                                                    <option value="Piece">Piece</option>
                                                                    <option value="Pad">Pad</option>
                                                                    <option value="Liter">Liter</option>
                                                                </select>
                                                            ) : (
                                                                item.unit
                                                            )}
                                                        </td>
                                                        <td width="">
                                                            {editingIndex === index ? (
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={item.unitPrice}
                                                                    onChange={(e) =>
                                                                        handleEditingItemInputChange(
                                                                            e,
                                                                            'unitPrice',
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                ToCurrencyFormat(item.unitPrice)
                                                            )}
                                                        </td>
                                                        <td>{ToCurrencyFormat(item.grossAmount)}</td>
                                                    </tr>
                                                ))}
                                                {items.length > 0 && (
                                                    <tr>
                                                        <td colSpan="6"></td>
                                                        <td colSpan="1">Grand Total:</td>
                                                        <td colSpan="1">
                                                            <b>
                                                                {ToCurrencyFormat(
                                                                    items.reduce((accumulator, item) => {
                                                                        return (
                                                                            parseFloat(accumulator) +
                                                                            parseFloat(item.grossAmount)
                                                                        );
                                                                    }, 0)
                                                                )}
                                                            </b>
                                                        </td>
                                                    </tr>
                                                )}

                                                {!items.length && (
                                                    <tr>
                                                        <td colSpan="9" className="text-center">
                                                            No items yet.
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-12 my-0 d-flex justify-content-between">
                                        <div>
                                            <button type="submit" className="btn btn-primary me-2">
                                                Submit
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-warning"
                                                onClick={() => clearForm()}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={() => goBack()}
                                            >
                                                <i className="bi bi-arrow-90deg-left"> </i>
                                                Recent Procurement
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
