import { Link, useNavigate } from 'react-router-dom';

function NotFound() {
    const navigate = useNavigate();

    return (
        <div>
            <section className="section dashboard">
                <div className="col-12 text-center p-4">
                    <h1>404</h1>
                    <p>Page not found</p>
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                        }}
                    >
                        go back
                    </a>
                </div>
            </section>
        </div>
    );
}

export default NotFound;
