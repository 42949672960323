export default function BadgesComponent(event) {
    const { status } = event;

    const statusTypes = [
        {
            shortDesc: 'P',
            longDesc: 'Pending',
            badgeClass: 'warning'
        },
        {
            shortDesc: 'A',
            longDesc: 'Approved',
            badgeClass: 'success'
        },
        {
            shortDesc: 'D',
            longDesc: 'Denied',
            badgeClass: 'danger'
        }
    ];
    const statusFiltered = statusTypes.filter((item) => item.shortDesc === status)[0] || 'Invalid Status';
    const statusText = statusFiltered?.longDesc || 'Invalid Status';
    const badgeClass = statusFiltered?.badgeClass || 'default';

    return <span className={`badge bg-${badgeClass} me-2`}>{statusText}</span>;
}
