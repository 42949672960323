import React, { useState, useEffect } from 'react';
import { FormDataGetService, FormSaveService, PaymentRequestSaveService } from '../../../../domain';
import { AuthContext } from '../../../../adapter/context';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import { ToCurrencyFormat, toDateOnly } from '../../../../utils';
import { useNavigate } from 'react-router-dom';

const Form = styled.form`
    opacity: ${(props) => (props.loading ? '0.4' : '1')};
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
`;

const SpinnerWrapper = styled.div`
    display: ${(props) => (props.loading ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`;

export default function PaymentRequestForm() {
    const navigate = useNavigate();
    const { user, updateUser } = AuthContext();
    const [data, setData] = useState({});
    const [items, setItems] = useState([]);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [loading, setIsLoading] = useState(false);
    const [editingItem, setEditingItem] = useState(null);

    const formDataDefaultValue = {
        to: 'Loading...',
        from: `${user.FULLNAME}`,
        divisionUnit: 'Loading...',
        formDate: toDateOnly(new Date()),
        payee: '',
        clientNo: '',
        amount: '',
        paymentMode: null,
        formDescription: '',
        newItem: {
            description: '',
            itemAmount: '',
            itemType: 'PCC Code',
            itemSource: '',
            branchDesc: '',
            glCode: ''
        }
    };
    const [formData, setFormData] = useState(formDataDefaultValue);

    const _formDataGet = new FormDataGetService();
    const _formDataSubmit = new PaymentRequestSaveService();

    const requestedData = async (formCleared) => {
        try {
            setIsLoading(true);
            const response = await _formDataGet.RequestData({ size: 50, page: 1, search: '' });
            console.log('request data response: ', response);

            setData(response);
            setIsLoading(false);
            const currentFormData = formCleared ? formDataDefaultValue : formData;
            setFormData({
                ...currentFormData,
                // to: response.approvers.find((item) => item.user_id === user.APPROVER)?.fullname ?? '' || ( user.type === 'AP' ? 'Approver' : ''),
                to:
                    (response.approvers.find((item) => item.user_id === user.APPROVER) ??
                        (user.type === 'AP' ? 'Current User is an Approver' : '')) ||
                    '',
                // divisionUnit: response.departments.find((item) => item.DEPARTMENT_ID === user.department_id),
                divisionUnit: response.branches.find((item) => item.BRANCH_ID === user.BRANCH_ID)
            });
        } catch (error) {
            console.error('error occured', error);
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong.',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
        }
    };

    const handleAddItem = () => {
        const { description, itemAmount, itemSource, itemType, glCode } = formData.newItem;

        if (description && itemAmount && itemSource) {
            let branchDesc;
            let approverId;
            if (itemType === 'PCC Code') {
                const selectedBranch = data.branches.filter(
                    (item) => (item?.BRANCH_ID || item?.SUB_BRANCH_ID) == itemSource
                );

                if (!data.branches.length || !selectedBranch.length) {
                    alert('Selected branch is Invalid.');
                    return;
                }
                branchDesc = selectedBranch[0].PCC + ' - ' + selectedBranch[0].BRANCH_NAME;
                if (branchDesc === '') branchDesc = 'notFound';
                console.log('branchDesc', branchDesc);

                approverId = data.approvers.filter((item) => (item?.branch_id || item?.sub_branch_id) == itemSource)[0]
                    ?.user_id;

                if (!approverId) {
                    alert('Selected branch does not have Existing Approver.');
                    return;
                }
                console.log('approverId', approverId);
            }

            setItems([
                ...items,
                {
                    description,
                    itemAmount: itemAmount,
                    itemType,
                    itemSource,
                    approverId,
                    branchDesc,
                    glCode
                }
            ]);

            setFormData({
                ...formData,
                newItem: {
                    description: '',
                    itemAmount: '',
                    itemType: 'PCC Code',
                    itemSource: '',
                    branchDesc: '',
                    glCode: ''
                }
            });
        } else {
            alert('Please fill the Items to Proceed.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const priceRegex = /^\d*\.?\d{0,2}$/;

        if (name === 'itemAmount' && (value === '' || priceRegex.test(value))) {
            setFormData({
                ...formData,
                newItem: {
                    ...formData.newItem,
                    [name]: value
                }
            });
        }

        if (name !== 'itemAmount') {
            if (['description', 'itemSource', 'glCode'].includes(name)) {
                setFormData({
                    ...formData,
                    newItem: {
                        ...formData.newItem,
                        [name]: value
                    }
                });
            }
        }
    };

    const editItem = (index) => {
        const editingItem = JSON.parse(JSON.stringify(items[index]));
        setEditingItem(editingItem);
        // console.log('editingItem: ', editingItem);

        setEditingIndex(index);
    };

    const handleEditingItemInputChange = (e, field, index, item = {}) => {
        const { name, value } = e.target;

        const quantityRegex = /^\d+$/;
        const priceRegex = /^\d*\.?\d{0,2}$/;
        const newItems = [...items];

        if (field === 'quantity' && (value === '' || (quantityRegex.test(value) && parseInt(value) > 0))) {
            newItems[index][field] = e.target.value;
        }

        if (field === 'unitPrice' && (value === '' || priceRegex.test(value))) {
            newItems[index][field] = e.target.value;
            console.log('success');
        } else {
            console.log('failed');
        }

        if (field !== 'quantity' && field !== 'unitPrice') {
            newItems[index][field] = e.target.value;
        }

        newItems[index]['grossAmount'] = newItems[index]['quantity'] * newItems[index]['unitPrice'];

        if (item?.itemType === 'PCC Code') {
            const selectedBranch = data.branches.filter((item) => item.BRANCH_ID == newItems[index]['itemSource']);

            if (!data.branches.length || !selectedBranch.length) {
                console.log(selectedBranch);
                alert('Selected branch is Invalid.');
                return;
            }
            const branchDesc = selectedBranch[0].PCC + ' - ' + selectedBranch[0].BRANCH_NAME;

            if (branchDesc === '') branchDesc = 'notFound';
            // console.log('branchDesc', branchDesc);
            newItems[index]['branchDesc'] = branchDesc;
        }

        setItems(newItems);
    };

    const applyItemChanges = (index) => {
        setEditingIndex(-1);
        // console.log('Item updated: ', items[index]);
    };

    const cancelItemEditing = async (index) => {
        setIsLoading(true);
        const alertResponse = await Swal.fire({
            title: 'Confirm',
            text: 'Discard edited values?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        });

        if (alertResponse.isConfirmed) {
            items[index] = editingItem;
            setEditingIndex(-1);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    const deleteItem = async (index) => {
        setIsLoading(true);
        const alertResponse = await Swal.fire({
            title: 'Confirm',
            text: 'Delete Item?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        });

        if (alertResponse.isConfirmed) {
            const newItems = items.filter((item, i) => i !== index);
            setItems(newItems);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (editingIndex !== -1) {
            alert('Please commit all changes before saving.');
            return;
        }

        if (!items.length) {
            alert('Please add Items to proceed.');
            return;
        }

        const itemsArray = items.map((item) => ({
            description: item.description,
            itemAmount: parseFloat(item.itemAmount).toFixed(2),
            itemSource: item.itemSource,
            itemType: item.itemType,
            glCode: item.glCode,
            created_by: user.id
        }));

        const transactionPayload = {
            data: {
                paymentRequestData: {
                    to: formData.to?.user_id,
                    from: user.id,
                    department: formData.divisionUnit?.DEPARTMENT_ID,
                    date: formData.formDate,
                    paymentMode: formData.paymentMode,
                    payee: formData.payee,
                    clientNo: formData.clientNo,
                    amount: parseFloat(formData.amount).toFixed(2),
                    description: formData.formDescription,
                    created_by: user.id,
                    paymentRequestItemData: itemsArray
                },
                signature: ''
            }
        };

        console.log('transactionPayload: ', transactionPayload);

        setIsLoading(true);
        const response = await _formDataSubmit.execute(transactionPayload);
        console.log('response', response);

        if (!response.data.ok) {
            Swal.fire({
                title: 'Error!',
                text: 'Payment Request Save failed. ',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
            return;
        }
        setFormData(formDataDefaultValue);
        setItems([]);
        setIsLoading(false);
        Swal.fire({
            title: 'Success!',
            text: 'Payment Request Save Success. ',
            icon: 'success',
            confirmButtonText: 'Ok'
        });
        navigate('/app/forms/recent-payment-request');
        requestedData(true);
    };

    const clearForm = async () => {
        setIsLoading(true);
        const alertResponse = await Swal.fire({
            title: 'Confirm',
            text: 'Cancel Payment Request Form Creation?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        });

        if (alertResponse.isConfirmed) {
            setFormData(formDataDefaultValue);
            setItems([]);
            setEditingIndex(-1);
            setEditingItem(null);
            setIsLoading(false);
            requestedData(true);
        } else {
            setIsLoading(false);
        }
    };

    const goBack = async () => {
        setIsLoading(true);
        const alertResponse = await Swal.fire({
            title: 'Confirm',
            text: 'Cancel Payment Request Form Creation?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        });

        if (alertResponse.isConfirmed) {
            navigate('/app/forms/recent-payment-request');
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        requestedData();
    }, []);

    return (
        <div>
            <section className="section">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Payment Request Details</h5>

                                <SpinnerWrapper loading={loading ? 1 : 0}>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ width: '30px', height: '30px' }}
                                    ></span>
                                </SpinnerWrapper>

                                <Form
                                    onSubmit={handleSubmit}
                                    className="row g-3"
                                    loading={loading ? 1 : 0}
                                    autoComplete="off"
                                    disabled={loading}
                                >
                                    <div className="col-12">
                                        <div className="row mb-2" style={{ display: 'none' }}>
                                            <div className="col-2">To</div>
                                            <div className="col-10">
                                                {typeof formData.to === 'object' ? formData.to?.fullname : formData.to}
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-2">From</div>
                                            <div className="col-10">{formData.from}</div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-2">Division/Unit</div>
                                            <div className="col-10">
                                                {typeof formData.divisionUnit === 'object'
                                                    ? formData.divisionUnit?.BRANCH_NAME
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-2">Date</div>
                                            <div className="col-10">{formData.formDate}</div>
                                        </div>
                                    </div>

                                    <hr style={{ border: 'dashed 2px black' }} />

                                    <div className="col-12">
                                        <div className="row mb-4">
                                            <div className="col-12">
                                                Please {''}
                                                <input
                                                    className=" checkbox-style"
                                                    type="radio"
                                                    name="paymentMode"
                                                    id="paymentMode1"
                                                    checked={formData.paymentMode === 'issue check' ? true : false}
                                                    onChange={(e) =>
                                                        setFormData((val) => ({
                                                            ...val,
                                                            paymentMode: 'issue check'
                                                        }))
                                                    }
                                                    required
                                                />
                                                <label htmlFor="paymentMode1" className="mx-2">
                                                    {' '}
                                                    issue check{' '}
                                                </label>
                                                <input
                                                    className=" checkbox-style"
                                                    type="radio"
                                                    name="paymentMode"
                                                    id="paymentMode2"
                                                    checked={formData.paymentMode === 'auto credit' ? true : false}
                                                    onChange={(e) =>
                                                        setFormData((val) => ({
                                                            ...val,
                                                            paymentMode: 'auto credit'
                                                        }))
                                                    }
                                                    required
                                                />
                                                <label htmlFor="paymentMode2" className="mx-2">
                                                    auto credit{' '}
                                                </label>
                                                <input
                                                    className=" checkbox-style"
                                                    type="radio"
                                                    name="paymentMode"
                                                    id="paymentMode3"
                                                    checked={formData.paymentMode === 'direct credit' ? true : false}
                                                    onChange={(e) =>
                                                        setFormData((val) => ({
                                                            ...val,
                                                            paymentMode: 'direct credit'
                                                        }))
                                                    }
                                                    required
                                                />
                                                <label htmlFor="paymentMode3" className="mx-2">
                                                    direct credit the payment due the following
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col-2">Payee</div>
                                            <div className="col-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="payee"
                                                    value={formData.payee}
                                                    required
                                                    onChange={(e) =>
                                                        setFormData((val) => ({ ...val, payee: e.target.value }))
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col-2">Client No.</div>
                                            <div className="col-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="clientNo"
                                                    value={formData.clientNo}
                                                    required
                                                    onChange={(e) =>
                                                        setFormData((val) => ({ ...val, clientNo: e.target.value }))
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col-2">Amount</div>
                                            <div className="col-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="amount"
                                                    value={formData.amount}
                                                    required
                                                    onChange={(e) => {
                                                        const priceRegex = /^\d*\.?\d{0,2}$/;
                                                        if (priceRegex.test(e.target.value)) {
                                                            setFormData((val) => ({ ...val, amount: e.target.value }));
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-4 mb-2">
                                            <div className="col-12">Charging would be as follows:</div>
                                        </div>
                                    </div>

                                    <hr style={{ border: 'dashed 2px black' }} />

                                    <div className="col-12">
                                        <h5 className="card-title my-0 pt-1 pb-3">Payment Request Items</h5>

                                        <div className="row">
                                            <div className="col-3">
                                                <label className=" col-form-label">GL / Account Description</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="description"
                                                    value={formData?.newItem?.description}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-1">
                                                <label className=" col-form-label">Type</label>
                                                <select
                                                    className="form-select"
                                                    aria-label="Select Type"
                                                    value={formData?.newItem?.itemType}
                                                    id="itemType"
                                                    onChange={(e) =>
                                                        setFormData((val) => ({
                                                            ...val,
                                                            newItem: {
                                                                ...formData.newItem,
                                                                itemType: e.target.value,
                                                                itemSource: ''
                                                            }
                                                        }))
                                                    }
                                                >
                                                    <option value="">Select Type</option>
                                                    <option value="PCC Code">PCC Code</option>
                                                    <option value="Agency Code">Agency Code</option>
                                                    <option value="Policy No">Policy No.</option>
                                                    <option value="Claim No">Claim No.</option>
                                                </select>
                                            </div>
                                            <div className="col-3">
                                                <label className=" col-form-label">
                                                    PCC Code / Agency Code / Policy No.
                                                </label>
                                                {formData?.newItem?.itemType === 'PCC Code' && (
                                                    <select
                                                        className="form-control"
                                                        name="itemSource"
                                                        value={formData?.newItem?.itemSource}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option>Select</option>
                                                        {data.branches &&
                                                            data.branches.length &&
                                                            data.branches.map((item, index) => {
                                                                return (
                                                                    <option
                                                                        value={item.BRANCH_ID ?? item.SUB_BRANCH_ID}
                                                                        key={`branches_${index}_${
                                                                            item.BRANCH_ID ?? item.SUB_BRANCH_ID
                                                                        }`}
                                                                    >
                                                                        {item.PCC} - {item.BRANCH_NAME}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                )}
                                                {formData?.newItem?.itemType !== 'PCC Code' && (
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="itemAmount"
                                                        value={formData?.newItem?.itemSource}
                                                        onChange={(e) =>
                                                            setFormData((val) => ({
                                                                ...val,
                                                                newItem: {
                                                                    ...formData.newItem,
                                                                    itemSource: e.target.value
                                                                }
                                                            }))
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div className="col-2">
                                                <label className=" col-form-label">GL Code</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="glCode"
                                                    value={formData?.newItem?.glCode}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-2">
                                                <label className=" col-form-label">Amount</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="itemAmount"
                                                    value={formData?.newItem?.itemAmount}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-1">
                                                <label className="col-form-label" style={{ color: 'white' }}>
                                                    .
                                                </label>
                                                <div className="d-grid">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary rounded-pill"
                                                        onClick={() => handleAddItem()}
                                                    >
                                                        <i className="bi bi-plus-lg"> </i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <br />

                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>#</th>
                                                    <th>GL Account Description</th>
                                                    <th>PCC Code / Agency Code / Policy No.</th>
                                                    <th>GL Code</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {items.map((item, index) => (
                                                    <tr key={'itemstbl_' + index}>
                                                        <td width="1">
                                                            {editingIndex === index ? (
                                                                <div className="d-flex flex-row">
                                                                    <div className="me-1">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-success rounded-pill btn-sm"
                                                                            onClick={() => applyItemChanges(index)}
                                                                        >
                                                                            <i className="bi bi-check-lg"> </i>
                                                                        </button>
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-warning rounded-pill btn-sm"
                                                                        onClick={() => cancelItemEditing(index)}
                                                                    >
                                                                        <i className="bi bi-x-circle"></i>
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                <div className="d-flex flex-row">
                                                                    <div className="me-1">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-secondary rounded-pill btn-sm"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                editItem(index);
                                                                            }}
                                                                        >
                                                                            <i className="bi bi-pencil-fill"> </i>
                                                                        </button>
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger rounded-pill btn-sm"
                                                                        onClick={() => deleteItem(index)}
                                                                    >
                                                                        <i className="bi bi-trash-fill"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {editingIndex === index ? (
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={item.description}
                                                                    onChange={(e) =>
                                                                        handleEditingItemInputChange(
                                                                            e,
                                                                            'description',
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                item.description
                                                            )}
                                                        </td>
                                                        <td>
                                                            {editingIndex === index ? (
                                                                item?.itemType === 'PCC Code' ? (
                                                                    <select
                                                                        className="form-control"
                                                                        name="itemSource"
                                                                        id="itemSource"
                                                                        value={item.itemSource}
                                                                        onChange={(e) =>
                                                                            handleEditingItemInputChange(
                                                                                e,
                                                                                'itemSource',
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        <option value="">Please choose</option>
                                                                        {data.branches &&
                                                                            data.branches.length &&
                                                                            data.branches.map((branch) => (
                                                                                <option
                                                                                    value={
                                                                                        branch.BRANCH_ID ??
                                                                                        branch.SUB_BRANCH_ID
                                                                                    }
                                                                                    key={`branchItem${index}_${
                                                                                        branch.BRANCH_ID ??
                                                                                        branch.SUB_BRANCH_ID
                                                                                    }`}
                                                                                >
                                                                                    {branch.PCC} - {branch.BRANCH_NAME}
                                                                                </option>
                                                                            ))}
                                                                    </select>
                                                                ) : (
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="itemAmount"
                                                                        value={item?.itemSource}
                                                                        onChange={(e) =>
                                                                            // setFormData((val) => ({
                                                                            //     ...val,
                                                                            //     newItem: {
                                                                            //         ...formData.newItem,
                                                                            //         itemSource: e.target.value
                                                                            //     }
                                                                            // }))
                                                                            handleEditingItemInputChange(
                                                                                e,
                                                                                'itemSource',
                                                                                index,
                                                                                item
                                                                            )
                                                                        }
                                                                    />
                                                                )
                                                            ) : item?.itemType === 'PCC Code' ? (
                                                                item.branchDesc
                                                            ) : (
                                                                item.itemSource
                                                            )}
                                                        </td>
                                                        <td width="">
                                                            {editingIndex === index ? (
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={item?.glCode}
                                                                    onChange={(e) =>
                                                                        handleEditingItemInputChange(e, 'glCode', index)
                                                                    }
                                                                />
                                                            ) : (
                                                                item?.glCode
                                                            )}
                                                        </td>
                                                        <td width="">
                                                            {editingIndex === index ? (
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={item?.itemAmount}
                                                                    onChange={(e) =>
                                                                        handleEditingItemInputChange(
                                                                            e,
                                                                            'itemAmount',
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                ToCurrencyFormat(item.itemAmount)
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                                {items.length > 0 && (
                                                    <tr>
                                                        <td colSpan="4"></td>
                                                        <td colSpan="1">Grand Total:</td>
                                                        <td colSpan="1">
                                                            <b>
                                                                {ToCurrencyFormat(
                                                                    items.reduce((accumulator, item) => {
                                                                        return (
                                                                            parseFloat(accumulator) +
                                                                            parseFloat(item.itemAmount)
                                                                        );
                                                                    }, 0)
                                                                )}
                                                            </b>
                                                        </td>
                                                    </tr>
                                                )}

                                                {!items.length && (
                                                    <tr>
                                                        <td colSpan="10" className="text-center">
                                                            No items yet.
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                    <hr style={{ border: 'dashed 2px black' }} />

                                    <div className="col-12">
                                        <label htmlFor="form_description" className="form-label">
                                            Details/Description of the payment
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            name="form_description"
                                            value={formData.formDescription}
                                            required
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, formDescription: e.target.value }))
                                            }
                                        />

                                        <br />
                                    </div>

                                    <div className="col-12 my-0 d-flex justify-content-between">
                                        <div>
                                            <button type="submit" className="btn btn-primary me-2">
                                                Submit
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-warning"
                                                onClick={() => clearForm()}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={() => goBack()}
                                            >
                                                <i className="bi bi-arrow-90deg-left"> </i>
                                                Recent Payment Request
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
