export default async function DecryptTokenRequest(event) {
    try {
        const { token } = event;

        const response = await fetch('https:///nwtbrglvql.execute-api.ap-southeast-1.amazonaws.com/api/procurement/user/auth/decrypt', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: token
            })
        });   

        if (!response.ok) {
            throw new Error('Error occured...');
        }

        const data = await response.json();
        // console.log('decrypted response: ', data);

        return data;
    } catch (error) {
        console.log(error);
        throw new Error('Error occured...');
    }
};
