/**
 * Handles User Requests to Core Applications
 * @param {object} Config requestUrl, headers, body
 *
 * @return {{ response: { data: object }}} Promise { response }
 */

import { Encrypt, Decrypt } from '../../utils';

export default async function CoreRequest(
    config = {
        headers: {},
        requestUrl: '',
        body: {}
    }
) {
    try {
        const url = window.location.href;
        const [, stage, , flag] = url.split('.');
        const encryptedBody = Encrypt(config.body);

        const response = await fetch(
            // `${flag ? 'https://partners-api' : 'https://fes'}.${flag ? `${stage}.` : ''}bpimsapps.com${
            //     flag ? '' : '/partners'
            // }/${config.requestUrl}`,
            config.requestUrl,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...config.headers
                },
                body: config.body
                // body: JSON.stringify({ data: encryptedBody })
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // const parsedResponse = await response.json();

        // const decryptedData = JSON.parse(Decrypt(parsedResponse.data));

        // const decryptedResponse = new Response(JSON.parse(decryptedData), {
        //     headers: { 'Content-Type': 'application/json' },
        //     status: 200,
        //     statusText: ''
        // });

        // return decryptedResponse;
        return response;
    } catch (error) {
        console.error('CoreRequest api error', error);
        throw error;
    }
}
