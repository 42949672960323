import { useState, useEffect, useRef } from 'react';
import {
    UserSaveService,
    UserGetService,
    UserDeleteService,
    UserUpdateService,
    UserGetOneService,
    BranchGetAllService,
    ApproverGetService,
    DepartmentGetAllService
} from '../../../../domain';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import Table from './table';

const Form = styled.form`
    opacity: ${(props) => (props.loading ? '0.4' : '1')};
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
`;

const SpinnerWrapper = styled.div`
    display: ${(props) => (props.loading ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`;

export default function UserAccountsView() {
    const [formData, setFormData] = useState({});
    const [loading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [userList, setUserList] = useState(null);
    const [tableDetails, setTableDetails] = useState({ size: 10, page: 1 });
    const [search, setSearch] = useState(null);
    const debounceTimeout = useRef(null);

    const _userSave = new UserSaveService();
    const _userGet = new UserGetService();
    const _userDelete = new UserDeleteService();
    const _userUpdate = new UserUpdateService();
    const _userGetOne = new UserGetOneService();

    const _branchGetAll = new BranchGetAllService();
    const _approverGetAll = new ApproverGetService();
    const _DepartmentGetAll = new DepartmentGetAllService();

    const requestedData = async () => {
        try {
            setIsLoading(true);
            const [branches, approvers, departments] = await Promise.all([
                _branchGetAll.execute(),
                _approverGetAll.execute(),
                _DepartmentGetAll.execute()
            ]);

            const data = {
                branches: branches.data.branch,
                approvers: approvers.data.approvers,
                departments: departments.data.departments
            };

            setData(data);
            setIsLoading(false);

            console.log('requestedData: ', data);
        } catch (error) {
            console.error('error occured', error);
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong.',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
        }
    };

    const handleFormSubmit = async () => {
        try {
            formData.MIDDLENAME = formData.MIDDLENAME ? formData.MIDDLENAME : '';
            formData.isApprover = formData.isApprover ? formData.isApprover : false;

            if (formData.length < 8) {
                alert('Please complete the Form to Proceed.');
            }
            console.log('formdata: ', formData);

            setIsLoading(true);
            const response = await _userSave.RequestData(formData);

            if (!response.data.ok) {
                Swal.fire({
                    title: 'Error!',
                    text: 'User create failed. ' + response.data.error,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                });
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: 'User create success.',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
                setFormData({});
                loadUsers();
            }
            setIsLoading(false);
            console.log('user create response', response);
        } catch (error) {
            console.error('error occured: ', error);
            Swal.fire({
                title: 'Error!',
                text: 'User create failed.',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
        }
    };

    const handleFormUpdate = async () => {
        try {
            formData.MIDDLENAME = formData.MIDDLENAME ? formData.MIDDLENAME : '';
            formData.isApprover = formData.isApprover ? formData.isApprover : false;

            if (formData.length < 8) {
                alert('Please complete the Form to Proceed.');
            }
            console.log('formdata: ', formData);

            setIsLoading(true);
            const response = await _userUpdate.RequestData(formData);

            if (!response.data.ok) {
                Swal.fire({
                    title: 'Error!',
                    text: 'User update failed. ' + response.data.error,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                });
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: 'User update success.',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
                setFormData({});
                loadUsers();
            }
            setIsLoading(false);
            console.log('user update response', response);
        } catch (error) {
            console.error('error occured: ', error);
            Swal.fire({
                title: 'Error!',
                text: 'User update failed.',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
        }
    };

    const loadUsers = async (page) => {
        setIsLoading(true);
        const users = await _userGet.RequestData({
            size: 10,
            page: page || 1,
            search: search || ''
        });
        setIsLoading(false);

        if (!users.data.ok) {
            Swal.fire({
                title: 'Error!',
                text: 'User list get failed. ' + users.data.error,
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
        } else {
            setUserList(users.data.users);
            setTableDetails({
                ...tableDetails,
                totalPages: users.data.totalPages,
                totalData: users.data.totalData,
                currentPage: users.data.currentPage
            });
        }
        console.log('existing users: ', users);
    };

    const resetForm = async () => {
        setFormData({});
    };

    const handleDelete = async (event) => {
        try {
            setIsLoading(true);
            const alertResponse = await Swal.fire({
                title: 'Confirm',
                text: "Click 'OK' to proceed.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            });

            if (alertResponse.isConfirmed) {
                const response = await _userDelete.RequestData(event);
                setIsLoading(false);
                if (!response.data.ok) {
                    Swal.fire({
                        title: 'Error!',
                        text: 'User delete failed. ' + response.data.error,
                        icon: 'error',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    });
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: 'User delete success.',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                    loadUsers();
                }
                console.log('delete user: ', response);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.error('error occured: ', error);
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong.',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
        }
    };

    const handleEdit = async (event) => {
        try {
            setIsLoading(true);
            const response = await _userGetOne.execute({
                id: event.USER_ID
            });

            if (!response.data.ok) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Error retrieving user data. ' + response.data.error,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                });
            } else {
                const user = response.data.user[0];
                console.log(user);
                setFormData((e) => ({ ...user, isApprover: user.USER_TYPE === 'AP' ? true : false, isUpdating: true }));
            }
            setIsLoading(false);
        } catch (error) {
            console.error('error occured: ', error);
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong.',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!data && !userList) {
            requestedData();
            loadUsers();
        }

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            if (userList) {
                loadUsers();
            }
        }, 1000);

        return () => {
            clearTimeout(debounceTimeout.current);
        };
    }, [search]);

    return (
        <div>
            <section className="section">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">User Details</h5>
                            <div>
                                <SpinnerWrapper loading={loading ? 1 : 0}>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ width: '30px', height: '30px' }}
                                    ></span>
                                </SpinnerWrapper>
                            </div>
                            <div>
                                <Form
                                    onSubmit={formData.isUpdating ? handleFormUpdate : handleFormSubmit}
                                    className="row g-3"
                                    loading={loading ? 1 : 0}
                                    autoComplete="off"
                                    disabled={loading}
                                >
                                    <div className="col-4">
                                        <label htmlFor="firstname" className="form-label">
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="firstname"
                                            required
                                            value={formData.FIRSTNAME ? formData.FIRSTNAME : ''}
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, FIRSTNAME: e.target.value }))
                                            }
                                        />
                                    </div>

                                    <div className="col-4">
                                        <label htmlFor="middlename" className="form-label">
                                            Middle Name (optional)
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="middlename"
                                            value={formData.MIDDLENAME ? formData.MIDDLENAME : ''}
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, MIDDLENAME: e.target.value }))
                                            }
                                        />
                                    </div>

                                    <div className="col-4">
                                        <label htmlFor="lastname" className="form-label">
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lastname"
                                            required
                                            value={formData.LASTNAME ? formData.LASTNAME : ''}
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, LASTNAME: e.target.value }))
                                            }
                                        />
                                    </div>

                                    <div className="col-4">
                                        <label htmlFor="email" className="form-label">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            required
                                            value={formData.EMAIL_ADDRESS ? formData.EMAIL_ADDRESS : ''}
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, EMAIL_ADDRESS: e.target.value }))
                                            }
                                            disabled={!!formData?.isUpdating}
                                        />
                                    </div>

                                    <div className="col-4">
                                        <label htmlFor="department" className="form-label">
                                            Department
                                        </label>
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            value={formData.DEPARTMENT_ID ? formData.DEPARTMENT_ID : ''}
                                            id="department"
                                            required
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, DEPARTMENT_ID: e.target.value }))
                                            }
                                        >
                                            <option value="">Select Department</option>
                                            {data &&
                                                data.departments.map((item, index) => {
                                                    return (
                                                        <option
                                                            value={item.DEPARTMENT_ID}
                                                            key={'department' + index + item.DEPARTMENT_ID}
                                                        >
                                                            {item.DEPARTMENT_NAME}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>

                                    <div className="col-4">
                                        <label htmlFor="branches" className="form-label">
                                            Branches
                                        </label>
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            value={formData.BRANCH_ID ? formData.BRANCH_ID : ''}
                                            id="branches"
                                            required
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, BRANCH_ID: e.target.value }))
                                            }
                                        >
                                            <option value="">Select Branches</option>
                                            {data &&
                                                data?.branches?.map((item, index) => {
                                                    return (
                                                        <option
                                                            value={item.BRANCH_ID}
                                                            key={'branch' + index + item.BRANCH_ID}
                                                        >
                                                            {item.BRANCH_NAME}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="isApprover"
                                                checked={formData.isApprover || false}
                                                onChange={(e) =>
                                                    setFormData((val) => ({ ...val, isApprover: e.target.checked }))
                                                }
                                            />
                                            <label className="form-check-label" htmlFor="isApprover">
                                                Set as Approver
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="d-flex flex-row">
                                            <div className="me-3">
                                                <button className="btn btn-primary" type="submit">
                                                    {formData.isUpdating ? 'Update' : 'Submit'}
                                                </button>
                                            </div>
                                            <div className="">
                                                <button
                                                    type="reset"
                                                    className="btn btn-secondary"
                                                    onClick={() => resetForm()}
                                                >
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>

                    <Table
                        SpinnerWrapper={SpinnerWrapper}
                        setFormData={setFormData}
                        loading={loading}
                        setIsLoading={setIsLoading}
                        tableDetails={tableDetails}
                        userList={userList}
                        setSearch={setSearch}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        loadUsers={loadUsers}
                    />
                </div>
            </section>
        </div>
    );
}
