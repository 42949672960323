import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { RecentProcurementsDataGetService } from '../../../domain';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import { ToCurrencyFormat, toReadableDate } from '../../../utils';
import { PaginationComponent, BadgesComponent } from '.././../components/';

const InputStyle = styled.input`
    &:focus {
        // outline: ${(props) => (props.loading ? 'none' : 'auto')};
        box-shadow: ${(props) => (props.loading ? 'none' : 'auto')};
    }
`;

const Form = styled.form`
    opacity: ${(props) => (props.loading ? '0.4' : '1')};
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
`;

const SpinnerWrapper = styled.div`
    display: ${(props) => (props.loading ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`;
const TableDiv = styled.div`
    opacity: ${(props) => (props.loading ? '0.4' : '1')};
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
`;

const NoRecordIcon = styled.i`
    font-size: 50px;
    color: grey;
`;

export default function PaymentReview() {
    const debounceTimeout = useRef(null);
    const [procurementList, setProcurementList] = useState(null);
    const [loading, setIsLoading] = useState(false);
    const [tableDetails, setTableDetails] = useState({ size: 10, page: 1 });
    const [search, setSearch] = useState(null);

    const _procurementFormList = new RecentProcurementsDataGetService();

    const loadProcurements = async (page) => {
        setIsLoading(true);
        const response = await _procurementFormList.RequestData({
            size: 10,
            page: page || 1,
            search: search || ''
        });
        setIsLoading(false);
        if (!response.data.ok) {
            Swal.fire({
                title: 'Error!',
                text: 'Procurement list get failed. ' + response.data.error,
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
        } else {
            setProcurementList(response.data.procurementForm);
            setTableDetails({
                ...tableDetails,
                totalPages: response.data.totalPages,
                totalData: response.data.totalData,
                currentPage: response.data.currentPage
            });
        }
        console.log('procurement list: ', response);
    };

    const handleEdit = () => {};
    const handleDelete = () => {};

    useEffect(() => {
        if (!procurementList) {
            loadProcurements();
        }

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            if (procurementList) {
                loadProcurements();
            }
        }, 1000);

        return () => {
            clearTimeout(debounceTimeout.current);
        };
    }, [search]);

    return (
        <div>
            <section className="section dashboard">
                <div className="col-12">
                    <div className="card recent-sales overflow-auto">
                        <div className="card-body">
                            <div className="row py-2">
                                <div className="col-md-10">
                                    <h5 className="card-title">
                                        Payment Review
                                        <span></span>
                                    </h5>
                                </div>
                                <div className="col-2 py-2"></div>
                            </div>

                            <SpinnerWrapper loading={loading ? 1 : 0}>
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ width: '30px', height: '30px' }}
                                ></span>
                            </SpinnerWrapper>

                            <TableDiv loading={loading ? 1 : 0}>
                                <div className="row justify-content-end">
                                    <div className="col-6 mb-3">
                                        <InputStyle
                                            loading={loading ? 1 : 0}
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            value={tableDetails.search}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <table className="table table-hover">
                                    <thead>
                                        <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                                            <th scope="col">ID</th>
                                            <th scope="col">Reference</th>
                                            <th scope="col" width="10%">
                                                Requestor
                                            </th>
                                            <th scope="col" width="30%">
                                                Description
                                            </th>
                                            <th scope="col">Amount (Net)</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Created</th>
                                            <th scope="col">Last updated</th>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {procurementList &&
                                            procurementList.map((item, index) => {
                                                return (
                                                    <tr key={'procitem_' + index + item.TRANSACTION_ID}>
                                                        <th scope="row">{item.TRANSACTION_ID}</th>
                                                        <th scope="row" className="text-primary">
                                                            {item.REFERENCE_NO}
                                                        </th>
                                                        <td>{item.CREATED_BY_NAME}</td>
                                                        <td>{item.DESCRIPTION}</td>
                                                        <td>
                                                            {ToCurrencyFormat(
                                                                item.items.reduce((accumulator, procitem) => {
                                                                    return (
                                                                        parseFloat(accumulator) +
                                                                        parseFloat(procitem.GROSS_AMOUNT)
                                                                    );
                                                                }, 0)
                                                            )}
                                                        </td>
                                                        <td>
                                                            <BadgesComponent
                                                                status={item.APPROVAL_STATUS}
                                                                badgeClass={'warning'}
                                                            />
                                                        </td>
                                                        <td>{toReadableDate(item.CREATED_AT)}</td>
                                                        <td>{toReadableDate(item.UPDATED_AT)}</td>
                                                        <td width="2%">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary rounded-pill btn-sm"
                                                                title="Edit"
                                                                onClick={() => handleEdit(item)}
                                                            >
                                                                <i className="bi bi-pencil"></i>
                                                            </button>
                                                        </td>
                                                        <td width="2%">
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger rounded-pill btn-sm"
                                                                title="Delete"
                                                                onClick={() =>
                                                                    handleDelete({
                                                                        id: item.TRANSACTION_ID
                                                                    })
                                                                }
                                                            >
                                                                <i className="bi bi-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        {!procurementList ||
                                            (!procurementList.length && (
                                                <tr>
                                                    <td colSpan="10" className="text-center p-0">
                                                        <div className="p-2" style={{ backgroundColor: '#FAF9F6' }}>
                                                            <NoRecordIcon className="bi bi-exclamation-triangle" />
                                                            <div>No data available.</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>

                                <PaginationComponent
                                    totalData={tableDetails.totalData}
                                    currentPage={tableDetails.currentPage}
                                    totalPages={tableDetails.totalPages}
                                    onPageChange={loadProcurements}
                                />
                            </TableDiv>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
