import { BranchDeleteAdapter } from '../../../../adapter';

export default async function BranchDeleteUseCase(event) {
    const { id } = event;
    
    const requestUrl = 'https://nwtbrglvql.execute-api.ap-southeast-1.amazonaws.com/api/procurement/branch/delete';

    const payload = {
        data: {
            branchData: {
                id: id
            },
            signature: ''
        }
    };

    const result = await BranchDeleteAdapter(requestUrl, JSON.stringify(payload));
    return result;
}
