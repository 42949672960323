// Define a number
function toCurrencyFormat(str) {
    const number = parseFloat(str);
    if (number === NaN) {
        return 'Invalid Number';
    } else {
        // return str.toLocaleString('en-US', { style: 'currency', currency: 'PHP' });
        return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'PHP' });
    }
}

export default toCurrencyFormat;
