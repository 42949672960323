import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

function toReadableDate(date) {
    try {
        // return format(date, 'MMMM dd, yyyy h:mm a');
        return formatInTimeZone(date, '', 'MMMM dd, yyyy h:mm a');
    } catch (error) {
        console.log(error);
        return 'Invalid Date.';
    }
}

export default toReadableDate;
