// import { MockRequest } from '../../../infrastructure';
import { LoginRequest } from '../../../infrastructure';

/**
 * Handles User Login
 * @param {String} username
 * @param {String} password
 *
 * @return {{ token: String, User: { email: String }}} UserAccessCredentials
 */
async function handleLogin(username, password) {
    // login logic here
    const res = await LoginRequest(username, password);
    return res;
}

export default handleLogin;
