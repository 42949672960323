export default async function ProcurementApprovalsDataGetRequest(event) {
    try {
        const { size, page, search, user_id } = event;
        const response = await fetch(
            'https://nwtbrglvql.execute-api.ap-southeast-1.amazonaws.com/api/procurement/form/approvals/get',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    data: {
                        procurementFormData: {
                            size: size,
                            page: page,
                            search: search,
                            user_id: user_id,
                        },
                        signature: ''
                    }
                })
            }
        );

        if (!response.ok) {
            throw new Error(`Error occured: ${response.status}`);
        }

        const data = await response.json();
        // console.log('user get response: ', data);

        return data;
    } catch (error) {
        console.log(error);
        throw new Error('Error occured...');
    }
}
