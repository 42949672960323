import { ApproveItemAdapter } from '../../../adapter';

export default async function ApproveItemUseCase(event) {
    // prepare data
    const requestUrl =
        'https://nwtbrglvql.execute-api.ap-southeast-1.amazonaws.com/api/procurement/form/approvals/approve';

    const payload = {
        data: {
            procurementFormData: { ...event }
        }
    };

    // handle request
    const result = await ApproveItemAdapter(requestUrl, JSON.stringify(payload));

    return result;
}
