import { ApproverGetUseCase } from '../../../../application';

export default class UserDataGetService {

    async execute(event) {
        const data = await ApproverGetUseCase(event);

        return data;
    }
}
