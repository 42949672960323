import { BranchGetOneAdapter } from '../../../../adapter';

export default async function BranchGetOneUseCase(event) {
    const { id } = event;

    const requestUrl = 'https://nwtbrglvql.execute-api.ap-southeast-1.amazonaws.com/api/procurement/branch/get-one';

    const payload = {
        data: {
            branchData: {
                id
            },
            signature: ''
        }
    };

    const result = await BranchGetOneAdapter(requestUrl, JSON.stringify(payload));
    return result;
}
