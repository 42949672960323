import React from 'react';
import { createHashRouter, Navigate, RouterProvider } from 'react-router-dom';
import { PrivateLayout, AuthLayout } from '../layouts';
import {
    LoginView,
    DashboardView,
    NotFoundView,
    UserAccountsView,
    RecentProcurementRequestView,
    ProcurementFormView,
    ProcurementFormApprovalsView,
    PaymentRequestFormView,
    RecentPaymentRequestView,
    PaymentRequestFormApprovalsView,
    BranchView,
    VendorView,
    PaymentReviewView,
    DepartmentsView
} from '../../presentation';
import { AuthContext } from '../../adapter';

const router = createHashRouter([
    {
        index: true,
        element: <Navigate to="/app" replace />
    },
    {
        path: '/app',
        element: <PrivateLayout />,
        children: [
            {
                index: true,
                element: <Navigate to="/app/dashboard" replace />
            },
            {
                path: 'dashboard',
                element: <DashboardView />
            }
        ]
    },
    {
        path: '/app/forms',
        element: <PrivateLayout />,
        children: [
            {
                path: 'recent-procurement-request',
                element: <RecentProcurementRequestView />
            },
            {
                path: 'procurement-form',
                element: <ProcurementFormView />
            },
            {
                path: 'procurement-approvals',
                element: <ProcurementFormApprovalsView />
            },
            {
                path: 'recent-payment-request',
                element: <RecentPaymentRequestView />
            },
            {
                path: 'payment-request-form',
                element: <PaymentRequestFormView />
            },
            {
                path: 'payment-request-approvals',
                element: <PaymentRequestFormApprovalsView />
            },
            {
                path: 'payment-review',
                element: <PaymentRequestFormView />
            }
        ]
    },
    {
        path: '/app/finance',
        element: <PrivateLayout />,
        children: [
            {
                path: 'payment-review',
                element: <PaymentReviewView />
            }
        ]
    },
    {
        path: '/app/maintenance',
        element: <PrivateLayout isAdmin={true} />,
        children: [
            {
                path: 'user-accounts',
                element: <UserAccountsView isAdmin={true} />
            },
            // {
            //     path: 'departments',
            //     element: <DepartmentsView isAdmin={true} />
            // },
            {
                path: 'branches',
                element: <BranchView />
            },
            {
                path: 'vendors',
                element: <VendorView />
            }
        ]
    },
    {
        path: '/auth',
        element: <AuthLayout />,
        children: [
            {
                index: true,
                element: <Navigate to="/auth/login" replace />
            },
            {
                path: 'login',
                element: <LoginView />
            },
            {
                path: '*',
                element: <NotFoundView />
            }
        ]
    },
    { path: '/unauthorized', element: <Navigate to="/auth" replace /> },
    {
        path: '*',
        element: <NotFoundView />
    }
]);

export default function MainRoutes() {
    const { user } = AuthContext();

    return (
        <RouterProvider router={router}>
            {user ? <Navigate to="/" replace /> : <Navigate to="/auth" replace />}
        </RouterProvider>
    );
}
