import { useState, useEffect, useRef } from 'react';
import {
    BranchSaveService,
    BranchGetService,
    BranchDeleteService,
    BranchUpdateService,
    BranchGetOneService,
    BranchGetAllService
} from '../../../../domain';
import styled from 'styled-components';
import BranchTable from './table';
import Swal from 'sweetalert2';

const Form = styled.form`
    opacity: ${(props) => (props.loading ? '0.4' : '1')};
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
`;

const SpinnerWrapper = styled.div`
    display: ${(props) => (props.loading ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`;

export default function BranchView() {
    const [formData, setFormData] = useState({});
    const [loading, setIsLoading] = useState(false);
    const [branchList, setBranchList] = useState(null);
    const [branchAllList, setBranchAllList] = useState(null);
    const [tableDetails, setTableDetails] = useState({ size: 10, page: 1 });
    const [search, setSearch] = useState(null);
    const debounceTimeout = useRef(null);
    const [modalState, setModalState] = useState({
        isOpen: false,
        items: null
    });

    const _branchSave = new BranchSaveService();
    const _branchGet = new BranchGetService();
    const _branchGetAll = new BranchGetAllService();
    const _branchGetOne = new BranchGetOneService();
    const _branchDelete = new BranchDeleteService();
    const _branchUpdate = new BranchUpdateService();

    const handleFormSubmit = async () => {
        if (formData.length < 8) {
            alert('Please complete the Form to Proceed.');
        }
        console.log('formdata: ', formData);

        // build payload
        const setPayload = {
            ...formData,
            PARENT_BRANCH_ID: formData?.PARENT_BRANCH_ID === 'NO PARENT' ? null : formData?.PARENT_BRANCH_ID,
            IS_SUB_BRANCH: formData?.PARENT_BRANCH_ID === 'NO PARENT' ? 0 : 1
        };
        console.log('setPayload', setPayload);

        setIsLoading(true);

        const response = await _branchSave.execute(setPayload);

        console.log('rere', response);
        if (!response.data.ok) {
            Swal.fire({
                title: 'Error!',
                text: 'Branch create failed. ' + response.data.error,
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
        } else {
            Swal.fire({
                title: 'Success!',
                text: 'Branch create success.',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then((result) => {
                setFormData({});
                setIsLoading(false);
                loadBranches();
                console.log('branch create response', response);
            });
        }
    };

    const handleFormUpdate = async () => {
        if (formData.length < 8) {
            alert('Please complete the Form to Proceed.');
        }
        console.log('formdata: ', formData);

        const setPayload = {
            ...formData,
            PARENT_BRANCH_ID: formData?.PARENT_BRANCH_ID === 'NO PARENT' ? null : formData?.PARENT_BRANCH_ID,
            IS_SUB_BRANCH: formData?.PARENT_BRANCH_ID === 'NO PARENT' ? 0 : 1
        };
        console.log('setPayload', setPayload);

        setIsLoading(true);
        const response = await _branchUpdate.execute(setPayload);

        if (!response.data.ok) {
            Swal.fire({
                title: 'Error!',
                text: 'Branch update failed. ' + response.data.error,
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
        } else {
            Swal.fire({
                title: 'Success!',
                text: 'Branch update success.',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            setFormData({});
        }
        setIsLoading(false);
        loadBranches();
        console.log('branch update response', response);
    };

    const loadBranches = async (page) => {
        setIsLoading(true);
        const response = await _branchGet.execute({
            size: 10,
            page: page || 1,
            search: search || ''
        });

        setIsLoading(false);
        if (!response.data.ok) {
            Swal.fire({
                title: 'Error!',
                text: 'Branch list get failed. ' + response.data.error,
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
        } else {
            setBranchList(response.data.branch);
            setTableDetails({
                ...tableDetails,
                totalPages: response.data.totalPages,
                totalData: response.data.totalData,
                currentPage: response.data.currentPage
            });
        }
        console.log('existing branches: ', response);
    };

    const loadAllBranches = async () => {
        try {
            setIsLoading(true);
            const response = await _branchGetAll.execute({});

            setIsLoading(false);
            if (!response.data.ok) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Branch get all failed. ' + response.data.error,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                });
            } else {
                setBranchAllList(response.data.branch);
            }
            console.log('branches get all: ', response);
        } catch (error) {
            console.error('error occured: ', error);
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
        }
    };

    const resetForm = async () => {
        setFormData({});
    };

    const handleDelete = async (event) => {
        setIsLoading(true);
        const alertResponse = await Swal.fire({
            title: 'Confirm',
            text: "Click 'OK' to proceed.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        });

        if (alertResponse.isConfirmed) {
            const response = await _branchDelete.execute(event);
            setIsLoading(false);
            if (!response.data.ok) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Branch delete failed. ' + response.data.error,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                });
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: 'Branch delete success.',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
                loadBranches();
            }
            console.log('delete branch: ', response);
        } else {
            setIsLoading(false);
        }
    };

    const handleEdit = async (event) => {
        setIsLoading(true);
        const response = await _branchGetOne.execute({
            id: event.BRANCH_ID
        });

        if (!response.data.ok) {
            Swal.fire({
                title: 'Error!',
                text: 'Error retrieving branch data. ' + response.data.error,
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
        } else {
            const branch = response.data.branch[0];
            setFormData((e) => ({
                ...branch,
                PARENT_BRANCH_ID: branch.PARENT_BRANCH_ID ? branch.PARENT_BRANCH_ID : 'NO PARENT',
                isUpdating: true
            }));
        }
        setIsLoading(false);
    };

    const handleAddSubBranch = async (event) => {
        setFormData({
            ...formData,
            PARENT_BRANCH_ID: event.BRANCH_ID,
            BRANCH_ID: event.BRANCH_ID,
            parentBranch: { ...event },
            isAddingSubBranch: true
        });
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // You can set it to 'auto' if you don't want smooth scrolling
        });
    };

    useEffect(() => {
        loadAllBranches();
    }, []);

    useEffect(() => {
        if (!branchList) {
            loadBranches();
        }

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            if (branchList) {
                loadBranches();
            }
        }, 1000);

        return () => {
            clearTimeout(debounceTimeout.current);
        };
    }, [search]);

    return (
        <div>
            <section className="section">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Branch Details</h5>
                            <div>
                                <SpinnerWrapper loading={loading ? 1 : 0}>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ width: '30px', height: '30px' }}
                                    ></span>
                                </SpinnerWrapper>
                            </div>
                            <div>
                                <Form
                                    onSubmit={formData.isUpdating ? handleFormUpdate : handleFormSubmit}
                                    className="row g-3"
                                    loading={loading ? 1 : 0}
                                    autoComplete="off"
                                    disabled={loading}
                                >
                                    {formData.isAddingSubBranch && (
                                        <div className="col-12">
                                            <h6 className="mb-0">
                                                <i className="bi bi-exclamation-circle"> </i>
                                                Adding new sub-branch for Branch:{' '}
                                                <u>{formData?.parentBranch.BRANCH_NAME}</u>
                                            </h6>
                                        </div>
                                    )}

                                    <div className="col-5">
                                        <label htmlFor="branch_name" className="form-label">
                                            Parent Branch
                                        </label>
                                        <select
                                            className="form-select"
                                            aria-label="Branch selection"
                                            value={formData.PARENT_BRANCH_ID ? formData.PARENT_BRANCH_ID : ''}
                                            id="branches"
                                            required
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, PARENT_BRANCH_ID: e.target.value }))
                                            }
                                            disabled={formData?.isAddingSubBranch}
                                        >
                                            <option value="">Select Branch</option>
                                            <option value="NO PARENT">NO PARENT</option>
                                            {branchAllList &&
                                                branchAllList.map((item, index) => {
                                                    return (
                                                        <option
                                                            value={item.BRANCH_ID}
                                                            key={'branch' + index + item.BRANCH_ID}
                                                        >
                                                            {item.BRANCH_NAME}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>

                                    <div className="col-7">
                                        <label htmlFor="branch_name" className="form-label">
                                            Branch Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="branch_name"
                                            required
                                            value={formData.BRANCH_NAME ? formData.BRANCH_NAME : ''}
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, BRANCH_NAME: e.target.value }))
                                            }
                                        />
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="branch_address" className="form-label">
                                            Branch Address
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="branch_address"
                                            required
                                            value={formData.BRANCH_ADDRESS ? formData.BRANCH_ADDRESS : ''}
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, BRANCH_ADDRESS: e.target.value }))
                                            }
                                        />
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="pcc" className="form-label">
                                            PCC
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="pcc"
                                            required
                                            value={formData.PCC ? formData.PCC : ''}
                                            onChange={(e) => setFormData((val) => ({ ...val, PCC: e.target.value }))}
                                        />
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="pcc_name" className="form-label">
                                            PCC Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="pcc_name"
                                            required
                                            value={formData.PCC_NAME ? formData.PCC_NAME : ''}
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, PCC_NAME: e.target.value }))
                                            }
                                        />
                                    </div>

                                    <div className="col-12">
                                        <div className="d-flex flex-row">
                                            <div className="me-3">
                                                <button className="btn btn-primary" type="submit">
                                                    {formData.isUpdating ? 'Update' : 'Submit'}
                                                </button>
                                            </div>
                                            <div className="">
                                                <button
                                                    type="reset"
                                                    className="btn btn-secondary"
                                                    onClick={() => resetForm()}
                                                >
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>

                    <BranchTable
                        SpinnerWrapper={SpinnerWrapper}
                        setFormData={setFormData}
                        loading={loading}
                        setIsLoading={setIsLoading}
                        tableDetails={tableDetails}
                        branchList={branchList}
                        setSearch={setSearch}
                        handleAddSubBranch={handleAddSubBranch}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        loadBranches={loadBranches}
                        modalState={modalState}
                        setModalState={setModalState}
                        _branchGetOne={_branchGetOne}
                        formData={formData}
                    />
                </div>
            </section>
        </div>
    );
}
