import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../../adapter';

export default function AuthLayout() {
    const { user } = AuthContext();

    if (user) {
        return <Navigate to="/" replace />;
    }

    return <Outlet />;
}
