import styled from 'styled-components';
import { PaginationComponent } from '../../../components/';
import { toReadableDate, ToTitleCase } from '../../../../utils';

const TableDiv = styled.div`
    opacity: ${(props) => (props.loading ? '0.4' : '1')};
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
`;

const InputStyle = styled.input`
    &:focus {
        // outline: ${(props) => (props.loading ? 'none' : 'auto')};
        box-shadow: ${(props) => (props.loading ? 'none' : 'auto')};
    }
`;

const NoRecordIcon = styled.i`
    font-size: 50px;
    color: grey;
`;

export default function BranchTable({
    SpinnerWrapper,
    setFormData,
    loading,
    setIsLoading,
    userList,
    tableDetails,
    setSearch,
    handleEdit,
    handleDelete,
    loadUsers
}) {
    return (
        <TableDiv loading={loading ? 1 : 0}>
            <div className="card overflow-auto">
                <div className="card-body">
                    <h5 className="card-title">User List</h5>
                    <div>
                        <SpinnerWrapper loading={loading ? 1 : 0}>
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                style={{ width: '30px', height: '30px' }}
                            ></span>
                        </SpinnerWrapper>
                    </div>

                    <TableDiv loading={loading ? 1 : 0}>
                        <div className="row justify-content-end">
                            <div className="col-6 mb-3">
                                <InputStyle
                                    loading={loading ? 1 : 0}
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    value={tableDetails.search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </div>

                        <table className="table table-hover">
                            <thead>
                                <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                                    <th scope="col">ID</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Department</th>
                                    <th scope="col">Branch</th>
                                    <th scope="col">Created</th>
                                    <th scope="col">Last Update</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {userList &&
                                    userList.map((item, index) => {
                                        return (
                                            <tr key={'user_' + index + item.USER_ID}>
                                                <td scope="row">{item.USER_ID}</td>
                                                <td>
                                                    {`${ToTitleCase(item.FIRSTNAME)} ${ToTitleCase(
                                                        item.MIDDLENAME
                                                    )} ${ToTitleCase(item.LASTNAME)}`}
                                                </td>
                                                <td>
                                                    <span className="text-primary">{item.EMAIL_ADDRESS}</span>
                                                </td>
                                                <td>{item.USER_TYPE === 'AP' ? 'Approver' : 'User'}</td>
                                                <td>{item.DEPARTMENT?.DEPARTMENT_NAME}</td>
                                                <td>{item.BRANCH?.BRANCH_NAME}</td>
                                                <td>{toReadableDate(item.CREATED_AT)}</td>
                                                <td>{toReadableDate(item.UPDATED_AT)}</td>
                                                <td width="2%">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary rounded-pill btn-sm"
                                                        title="Edit"
                                                        onClick={() => handleEdit(item)}
                                                    >
                                                        <i className="bi bi-pencil"></i>
                                                    </button>
                                                </td>
                                                <td width="2%">
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger rounded-pill btn-sm"
                                                        title="Delete"
                                                        onClick={() =>
                                                            handleDelete({
                                                                id: item.USER_ID,
                                                                email: item.EMAIL_ADDRESS
                                                            })
                                                        }
                                                    >
                                                        <i className="bi bi-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                {!userList ||
                                    (!userList.length && (
                                        <tr>
                                            <td colSpan="11" className="text-center p-0">
                                                <div className="p-2" style={{ backgroundColor: '#FAF9F6' }}>
                                                    <NoRecordIcon className="bi bi-exclamation-triangle" />
                                                    <div>No Users available.</div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>

                        <PaginationComponent
                            totalData={tableDetails.totalData}
                            currentPage={tableDetails.currentPage}
                            totalPages={tableDetails.totalPages}
                            onPageChange={loadUsers}
                        />
                    </TableDiv>
                </div>
            </div>
        </TableDiv>
    );
}
