import { HandleLogin, HandleUserAuthentication } from '../../../application';

export default class AuthService {
    constructor() {}

    async RequestLogin(User, AuthenticateUserStore) {
        const login = await HandleLogin(User.username, User.password);
        await HandleUserAuthentication(login, AuthenticateUserStore);

        return;
    }
}
