import { useLocation, Link } from 'react-router-dom';
import { ToTitleCase } from '../../utils';

export default function BreadcrumbComponent() {
    const location = useLocation();

    const module = location.pathname.split('/').length ? location.pathname.split('/') : null;
    const moduleParent = module.length > 3 ? module[module.length - 2] : '';
    const moduleName = module[module.length - 1];

    return (
        <div className="row">
            <div className="pagetitle col-md-12">
                <h1>{ToTitleCase(moduleName)}</h1>
                <nav>
                    <ol className="breadcrumb">
                        <Link to="dashboard" className="breadcrumb-item">
                            <span>Home</span>
                        </Link>
                        {moduleParent && <li className="breadcrumb-item active">{ToTitleCase(moduleParent)}</li>}
                        <li className="breadcrumb-item active">{ToTitleCase(moduleName)}</li>
                    </ol>
                </nav>
            </div>
        </div>
    );
}
