export default async function LoginRequest(username, password) {
    try {
        const response = await fetch('https:///nwtbrglvql.execute-api.ap-southeast-1.amazonaws.com/api/procurement/user/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: username,
                password: password
            })
        });   
        
        if(!response.ok){
            throw new Error('Error occured...');
        }

        const data = await response.json();   
        console.log('response: ', data);

        return data;
    } catch (error) {
        console.log(error);
        throw new Error('Error occured...');
    }
}
