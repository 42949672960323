import styled from 'styled-components';
import { PaginationComponent, ModalSubDepartmentsComponent } from '../../../components/';
import { toReadableDate } from '../../../../utils';

const TableDiv = styled.div`
    opacity: ${(props) => (props.loading ? '0.4' : '1')};
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
`;

const InputStyle = styled.input`
    &:focus {
        // outline: ${(props) => (props.loading ? 'none' : 'auto')};
        box-shadow: ${(props) => (props.loading ? 'none' : 'auto')};
    }
`;

const NoRecordIcon = styled.i`
    font-size: 50px;
    color: grey;
`;

export default function Table({
    SpinnerWrapper,
    formData,
    loading,
    departmentsList,
    tableDetails,
    setSearch,
    handleEdit,
    handleDelete,
    loadVendors,
    setModalState,
    modalState
}) {
    const showDepartmentDetails = (e, data) => {
        e.preventDefault();
        setModalState({
            isOpen: true,
            data,
            formData: null
        });
    };

    return (
        <div>
            <div className="card overflow-auto">
                <div className="card-body">
                    <h5 className="card-title">Departments List</h5>
                    <div>
                        <SpinnerWrapper loading={loading ? 1 : 0}>
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                style={{ width: '30px', height: '30px' }}
                            ></span>
                        </SpinnerWrapper>
                    </div>

                    <TableDiv loading={loading ? 1 : 0}>
                        <div className="row justify-content-end">
                            <div className="col-6 mb-3">
                                <InputStyle
                                    loading={loading ? 1 : 0}
                                    id="search"
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    value={tableDetails.search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </div>

                        <table className="table table-hover">
                            <thead>
                                <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                                    <th scope="col">ID</th>
                                    <th scope="col">Department</th>
                                    <th scope="col">Created</th>
                                    <th scope="col">Last Update</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {departmentsList &&
                                    departmentsList.map((item, index) => {
                                        return (
                                            <tr key={'user_' + index + item.DEPARTMENT_ID}>
                                                <td scope="row">{item.DEPARTMENT_ID}</td>
                                                <th>
                                                    {!!item.IS_SUB_DEPARTMENT && (
                                                        <span className={`badge bg-secondary me-2`}>Sub</span>
                                                    )}
                                                    <a
                                                        href="#"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#departmentsmodal"
                                                        onClick={(e) => showDepartmentDetails(e, item)}
                                                    >
                                                        {item.DEPARTMENT_NAME}
                                                    </a>
                                                </th>
                                                <td>{toReadableDate(item.CREATED_AT)}</td>
                                                <td>{toReadableDate(item.UPDATED_AT)}</td>
                                                <td width="2%">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary rounded-pill btn-sm"
                                                        title="Edit"
                                                        onClick={() => handleEdit(item)}
                                                    >
                                                        <i className="bi bi-pencil"></i>
                                                    </button>
                                                </td>
                                                <td width="">
                                                    {formData.DEPARTMENT_ID !== item.DEPARTMENT_ID ? (
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger rounded-pill btn-sm"
                                                            title="Delete"
                                                            onClick={() =>
                                                                handleDelete({
                                                                    id: item.DEPARTMENT_ID
                                                                })
                                                            }
                                                        >
                                                            <i className="bi bi-trash"></i>
                                                        </button>
                                                    ) : (
                                                        <i>Editing...</i>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                {!departmentsList ||
                                    (!departmentsList.length && (
                                        <tr>
                                            <td colSpan="6" className="text-center p-0">
                                                <div className="p-2" style={{ backgroundColor: '#FAF9F6' }}>
                                                    <NoRecordIcon className="bi bi-exclamation-triangle" />
                                                    <div>No department/s available.</div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>

                        <PaginationComponent
                            totalData={tableDetails.totalData}
                            currentPage={tableDetails.currentPage}
                            totalPages={tableDetails.totalPages}
                            onPageChange={loadVendors}
                        />

                        <ModalSubDepartmentsComponent
                            modalState={modalState}
                            setModalState={setModalState}
                            format={toReadableDate}
                        />
                    </TableDiv>
                </div>
            </div>
        </div>
    );
}
