import { PaymentRequestApproveItemsAdapter } from '../../../adapter';

export default async function PaymentRequestApproveItemsUseCase(event) {
    const requestUrl =
        'https://nwtbrglvql.execute-api.ap-southeast-1.amazonaws.com/api/procurement/payment-request/approvals/approve';

    const payload = {
        data: {
            paymentRequestData: {
                ...event
            },
            signature: ''
        }
    };

    const result = await PaymentRequestApproveItemsAdapter(requestUrl, JSON.stringify(payload));
    return result;
}
