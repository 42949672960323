import { VendorGetAllAdapter } from '../../../../adapter';

export default async function VendorGetAllUseCase() {
    const requestUrl = 'https://nwtbrglvql.execute-api.ap-southeast-1.amazonaws.com/api/procurement/vendor/get-all';

    const payload = {};

    const result = await VendorGetAllAdapter(requestUrl, JSON.stringify(payload));
    return result;
}
