import { useState, useRef, useEffect } from 'react';
import { AuthContext } from '../../adapter/context/';
import bpimslogo from "../../assets/images/bpims-logo.png";

export default function HeaderComponent() {
    const scripts = [
        { src: '../assets/vendor/bootstrap/js/bootstrap.bundle.min.js' },
        // { src: '../assets/js/main.js' },
        // { src: '../assets/vendor/simple-datatables/simple-datatables.js' },
        // { src: '../assets/vendor/tinymce/tinymce.min.js' }
    ];

    const { user, updateUser } = AuthContext();

    const logout = () => {
        updateUser(null);
    };

    const addBodyClass = () => {
        document.body.classList.toggle('toggle-sidebar');
    };

    useEffect(() => {
        scripts.forEach((script) => {
            let existingScript = document.querySelector(`script[src="${script.src}"]`);

            if (!existingScript) {
                const el = document.createElement('script');
                el.src = script.src;
                el.async = false;
                document.body.appendChild(el);
            }
        });
    }, []);

    return (
        <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-between">
                <a href="index.html" className="logo d-flex align-items-center ">
                    {/* <img src={bpimslogo} alt="BPIMS Logo" style={{ marginRight: '10px'}} /> */}
                    <span className="d-lg-block"> BPIMS - PPRS </span>
                </a>
                <i className="bi bi-list toggle-sidebar-btn" id="toggle-sidebar-btn" onClick={ () => addBodyClass() }></i>
            </div>

            <div className="search-bar d-none">
                <form className="search-form d-flex align-items-center" method="POST" action="#">
                    <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
                    <button type="submit" title="Search">
                        <i className="bi bi-search"></i>
                    </button>
                </form>
            </div>

            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">
                    <li className="nav-item d-block d-lg-none">
                        <a className="nav-link nav-icon search-bar-toggle " href="#">
                            <i className="bi bi-search"></i>
                        </a>
                    </li>

                    <li className="nav-item dropdown d-none">
                        <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                            <i className="bi bi-bell"></i>
                            <span className="badge bg-primary badge-number">4</span>
                        </a>

                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                            <li className="dropdown-header">
                                You have 4 new notifications
                                <a href="#">
                                    <span className="badge rounded-pill bg-primary p-2 ms-2">View all</span>
                                </a>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>

                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li className="dropdown-footer">
                                <a href="#">Show all notifications</a>
                            </li>
                        </ul>
                    </li>

                    <li className="nav-item dropdown pe-3">
                        <a
                            className="nav-link nav-profile d-flex align-items-center pe-0"
                            href="#"
                            data-bs-toggle="dropdown"
                        >
                            {/* <img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" /> */}
                            <span className="d-none d-md-block dropdown-toggle ps-2">
                                { user.firstname[0] }. { user.lastname }
                            </span>
                        </a>

                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li className="dropdown-header">
                                <h6>
                                    {user.firstname} {user.middlename ? user.middlename : ''} {user.lastname}
                                </h6>
                                <span>{user.email}</span>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>

                            <li>
                                <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                                    <i className="bi bi-person"></i>
                                    <span>My Profile</span>
                                </a>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>

                            <li>
                                <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                                    <i className="bi bi-gear"></i>
                                    <span>Account Settings</span>
                                </a>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>

                            <li>
                                <a className="dropdown-item d-flex align-items-center" href="pages-faq.html">
                                    <i className="bi bi-question-circle"></i>
                                    <span>Need Help?</span>
                                </a>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>

                            <li>
                                <a
                                    className="dropdown-item d-flex align-items-center"
                                    href="#"
                                    onClick={() => logout()}
                                >
                                    <i className="bi bi-box-arrow-right"></i>
                                    <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </header>
    );
}
