import { useState, useEffect, useRef } from 'react';
import {
    VendorSaveService,
    VendorGetService,
    VendorDeleteService,
    VendorUpdateService,
    VendorGetOneService
} from '../../../../domain';
import styled from 'styled-components';
import VendorTable from './table';
import Swal from 'sweetalert2';
import { toNumberOnly } from '../../../../utils';

const Form = styled.form`
    opacity: ${(props) => (props.loading ? '0.4' : '1')};
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
`;

const SpinnerWrapper = styled.div`
    display: ${(props) => (props.loading ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`;

export default function VendorView() {
    const [formData, setFormData] = useState({});
    const [loading, setIsLoading] = useState(false);
    const [vendorList, setVendorList] = useState(null);
    const [tableDetails, setTableDetails] = useState({ size: 10, page: 1 });
    const [search, setSearch] = useState(null);
    const debounceTimeout = useRef(null);
    const [modalState, setModalState] = useState({
        isOpen: false,
        items: null
    });

    const _vendorSave = new VendorSaveService();
    const _vendorGet = new VendorGetService();
    const _vendorGetOne = new VendorGetOneService();
    const _vendorDelete = new VendorDeleteService();
    const _vendorUpdate = new VendorUpdateService();

    const handleFormSubmit = async (e) => {
        try {
            e.preventDefault();

            if (formData.length < 6) {
                alert('Please complete the Form to Proceed.');
            }
            console.log('formdata: ', formData);
            setIsLoading(true);

            const response = await _vendorSave.execute(formData);
            console.log('vendor create response', response);
            if (!response.data.ok) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Vendor create failed. ' + response.data.error,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                });
                setIsLoading(false);
                return;
            }
            setFormData({});
            setIsLoading(false);
            loadVendors();

            Swal.fire({
                title: 'Success!',
                text: 'Vendor create success.',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        } catch (error) {
            console.error('error occured', error);
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong.',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
        }
    };

    const handleEdit = async (event) => {
        try {
            setIsLoading(true);
            const response = await _vendorGetOne.execute({
                id: event.VENDOR_ID
            });

            if (!response.data.ok) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Error retrieving vendor data. ' + response.data.error,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                });
                setIsLoading(false);
                return;
            }
            const vendor = response.data.vendor[0];
            setFormData((e) => ({ ...vendor, isUpdating: true }));
            setIsLoading(false);
        } catch (error) {
            console.error('error occured', error);
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong.',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
        }
    };

    const handleFormUpdate = async (e) => {
        try {
            e.preventDefault();

            if (formData.length < 8) {
                alert('Please complete the Form to Proceed.');
            }
            console.log('formdata: ', formData);

            setIsLoading(true);
            const response = await _vendorUpdate.execute(formData);

            if (!response.data.ok) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Vendor update failed. ' + response.data.error,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                });
                setIsLoading(false);
                return;
            }
            Swal.fire({
                title: 'Success!',
                text: 'Vendor update success.',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            setFormData({});
            setIsLoading(false);
            loadVendors();
            console.log('vendor update response', response);
        } catch (error) {
            console.error('error occured', error);
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong.',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
        }
    };

    const handleDelete = async (event) => {
        try {
            setIsLoading(true);
            const alertResponse = await Swal.fire({
                title: 'Confirm',
                text: "Click 'OK' to proceed.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            });

            if (alertResponse.isConfirmed) {
                const response = await _vendorDelete.execute(event);
                setIsLoading(false);
                if (!response.data.ok) {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Vendor delete failed. ' + response.data.error,
                        icon: 'error',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    });
                    setIsLoading(false);
                    return;
                }
                Swal.fire({
                    title: 'Success!',
                    text: 'Vendor delete success.',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
                loadVendors();
                console.log('delete vendor: ', response);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.error('error occured', error);
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong.',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
        }
    };

    const loadVendors = async (page) => {
        try {
            setIsLoading(true);
            const response = await _vendorGet.execute({
                size: 10,
                page: page || 1,
                search: search || ''
            });
            if (!response.data.ok) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Vendor list get failed. ' + response.data.error,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                });
                setIsLoading(false);
                return;
            }
            setVendorList(response.data.vendors);
            setTableDetails({
                ...tableDetails,
                totalPages: response.data.totalPages,
                totalData: response.data.totalData,
                currentPage: response.data.currentPage
            });
            setIsLoading(false);

            console.log('existing vendors: ', response);
        } catch (error) {
            console.error('error occured', error);
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong.',
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
        }
    };

    const resetForm = async () => {
        setFormData({});
    };

    useEffect(() => {
        if (!vendorList) {
            loadVendors();
        }

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            if (vendorList) {
                loadVendors();
            }
        }, 1000);

        return () => {
            clearTimeout(debounceTimeout.current);
        };
    }, [search]);

    return (
        <div>
            <section className="section">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Vendor Details</h5>
                            <div>
                                <SpinnerWrapper loading={loading ? 1 : 0}>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ width: '30px', height: '30px' }}
                                    ></span>
                                </SpinnerWrapper>
                            </div>
                            <div>
                                <Form
                                    onSubmit={formData.isUpdating ? handleFormUpdate : handleFormSubmit}
                                    className="row g-3"
                                    loading={loading ? 1 : 0}
                                    autoComplete="off"
                                    disabled={loading}
                                >
                                    <div className="col-4">
                                        <label htmlFor="vendor_name" className="form-label">
                                            Vendor Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="vendor_name"
                                            required
                                            value={formData.VENDOR_NAME ?? ''}
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, VENDOR_NAME: e.target.value }))
                                            }
                                        />
                                    </div>

                                    <div className="col-8">
                                        <label htmlFor="vendor_address" className="form-label">
                                            Vendor Address
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="vendor_address"
                                            required
                                            value={formData.VENDOR_ADDRESS ?? ''}
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, VENDOR_ADDRESS: e.target.value }))
                                            }
                                        />
                                    </div>

                                    <div className="col-4">
                                        <label htmlFor="vendor_email" className="form-label">
                                            Vendor Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="vendor_email"
                                            required
                                            value={formData.VENDOR_EMAIL ?? ''}
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, VENDOR_EMAIL: e.target.value }))
                                            }
                                        />
                                    </div>

                                    <div className="col-4">
                                        <label htmlFor="vendor_code" className="form-label">
                                            Vendor Code
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="vendor_code"
                                            required
                                            value={formData.VENDOR_CODE ?? ''}
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, VENDOR_CODE: e.target.value }))
                                            }
                                        />
                                    </div>

                                    <div className="col-4">
                                        <label htmlFor="vendor_tin" className="form-label">
                                            Vendor Tin
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="vendor_tin"
                                            required
                                            value={formData.VENDOR_TIN ?? ''}
                                            onChange={(e) =>
                                                setFormData((val) => ({
                                                    ...val,
                                                    VENDOR_TIN: toNumberOnly(e.target.value)
                                                }))
                                            }
                                        />
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="vendor_description" className="form-label">
                                            Vendor Description
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            name="vendor_description"
                                            value={formData.VENDOR_DESCRIPTION ?? ''}
                                            required
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, VENDOR_DESCRIPTION: e.target.value }))
                                            }
                                        />
                                    </div>

                                    <div className="col-12">
                                        <div className="d-flex flex-row">
                                            <div className="me-3">
                                                <button className="btn btn-primary" type="submit">
                                                    {formData.isUpdating ? 'Update' : 'Submit'}
                                                </button>
                                            </div>
                                            <div className="">
                                                <button
                                                    type="reset"
                                                    className="btn btn-secondary"
                                                    onClick={() => resetForm()}
                                                >
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>

                    <VendorTable
                        SpinnerWrapper={SpinnerWrapper}
                        formData={formData}
                        loading={loading}
                        vendorList={vendorList}
                        tableDetails={tableDetails}
                        setSearch={setSearch}
                        handleEdit={handleEdit}
                        setFormData={setFormData}
                        handleDelete={handleDelete}
                        loadVendors={loadVendors}
                        setModalState={setModalState}
                    />
                </div>
            </section>
        </div>
    );
}
