import { VendorUpdateAdapter } from '../../../../adapter';

export default async function VendorUpdateUseCase(event) {
    const { VENDOR_ID, VENDOR_NAME, VENDOR_ADDRESS, VENDOR_EMAIL, VENDOR_CODE, VENDOR_TIN, VENDOR_DESCRIPTION } = event;

    const requestUrl = 'https://nwtbrglvql.execute-api.ap-southeast-1.amazonaws.com/api/procurement/vendor/update';

    const payload = {
        data: {
            vendorData: {
                id: VENDOR_ID,
                vendor_name: VENDOR_NAME,
                vendor_address: VENDOR_ADDRESS,
                vendor_email: VENDOR_EMAIL,
                vendor_code: VENDOR_CODE,
                vendor_tin: VENDOR_TIN,
                vendor_description: VENDOR_DESCRIPTION
            },
            signature: ''
        }
    };

    const result = await VendorUpdateAdapter(requestUrl, JSON.stringify(payload));
    return result;
}
