import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../../adapter';
import { HeaderComponent, SidebarComponent, BreadcrumbComponent } from '../../presentation/components';

export default function PrivateLayout({ isAdmin }) {
    const location = useLocation();
    const { user } = AuthContext();

    const modules = [
        {
            moduleType: 'single',
            moduleParent: null,
            moduleName: 'Dashboard',
            moduleIcon: 'bi bi-grid',
            linkTo: '/app/dashboard'
        },
        {
            moduleType: 'multiple',
            moduleParent: 'Forms',
            moduleIcon: 'bi bi-journal-text',
            modules: [
                {
                    name: 'Recent Procurement Request',
                    linkTo: '/app/forms/recent-procurement-request'
                },
                {
                    name: 'My Procurement Approvals',
                    linkTo: '/app/forms/procurement-approvals'
                },
                {
                    name: 'Recent Payment Request',
                    linkTo: '/app/forms/recent-payment-request'
                },
                {
                    name: 'My Payment Request Approvals',
                    linkTo: '/app/forms/payment-request-approvals'
                }
            ]
        },
        {
            moduleType: 'multiple',
            moduleParent: 'Finance',
            moduleIcon: 'bi bi-bar-chart',
            modules: [
                {
                    name: 'Payment Review',
                    linkTo: '/app/finance/payment-review'
                },
                {
                    name: 'Payment Approval',
                    linkTo: '/app/finance/payment-approval'
                },
                {
                    name: 'Payment Inquiry',
                    linkTo: '/app/finance/payment-inquiry'
                }
            ]
        },
        {
            moduleType: 'multiple',
            moduleParent: 'Maintenance',
            moduleIcon: 'bi bi-database-fill-check',
            modules: [
                {
                    name: 'User Accounts',
                    linkTo: '/app/maintenance/user-accounts'
                },
                // {
                //     name: 'Departments',
                //     linkTo: '/app/maintenance/departments'
                // },
                {
                    name: 'Branches',
                    linkTo: '/app/maintenance/branches'
                },
                {
                    name: 'Vendors',
                    linkTo: '/app/maintenance/vendors'
                }
            ]
        }
    ];

    if (!user && location.pathname !== '/unauthorized') {
        return <Navigate to="/unauthorized" replace />;
    }

    if (isAdmin && user.type === 'U') {
        return <Navigate to="/unauthorized" replace />;
    }

    return (
        <div className="">
            <HeaderComponent />
            <main id="main" className="main">
                <BreadcrumbComponent />
                <Outlet />
            </main>
            <SidebarComponent modules={modules} />
        </div>
    );
}
