import React from 'react';
import { MainRoute } from './infrastructure';
import { AuthContextProvider } from './adapter';
import './App.css';

function App() {
    return (
        <AuthContextProvider>
            <MainRoute />
        </AuthContextProvider>
    );
}

export default App;
