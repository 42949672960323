import { Link, useLocation } from 'react-router-dom';
import { ToTitleCase } from '../../utils';

export default function SidebarComponent({ modules }) {
    const location = useLocation();

    const module = location.pathname.split('/').length ? location.pathname.split('/') : null;
    const moduleParent = module.length > 3 ? module[module.length - 2] : '';
    const moduleName = module[module.length - 1];

    return (
        <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
                {modules &&
                    modules.map((item, index) => {
                        return item.moduleType === 'single' ? (
                            <li className="nav-item" key={'singleModule_' + index}>
                                <Link
                                    to={item.linkTo}
                                    className={`nav-link ${
                                        moduleName === item.moduleName.toLowerCase() ? '' : 'collapsed'
                                    }`}
                                >
                                    <i className={item.moduleIcon}></i>
                                    <span>{item.moduleName}</span>
                                </Link>
                            </li>
                        ) : (
                            item.moduleType === 'multiple' && (
                                <li className="nav-item" key={'multipleModule_' + index}>
                                    <a
                                        className={`nav-link ${
                                            moduleParent === item.moduleParent.toLowerCase() ? '' : 'collapsed'
                                        }`}
                                        data-bs-target={`#forms-nav` + index}
                                        data-bs-toggle="collapse"
                                        href="#"
                                    >
                                        <i className={item.moduleIcon}></i>
                                        <span>{item.moduleParent}</span>
                                        <i className="bi bi-chevron-down ms-auto"></i>
                                    </a>
                                    <ul
                                        id={`forms-nav` + index}
                                        className={`nav-content collapse ${
                                            moduleParent === item.moduleParent.toLowerCase() ? 'show' : ''
                                        }`}
                                        data-bs-parent={`forms-nav` + index}
                                    >
                                        {item.modules.map((itemOne, indexOne) => {
                                            return (
                                                <li key={'multipleChid_' + indexOne}>
                                                    <Link
                                                        to={itemOne.linkTo}
                                                        className={
                                                            moduleName === itemOne.linkTo.split('/')[3] ? 'active' : ''
                                                        }
                                                    >
                                                        <i className="bi bi-circle"></i>
                                                        <span>{ToTitleCase(itemOne.name)}</span>
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </li>
                            )
                        );
                    })}

                {/* <li className="nav-heading">User Pages</li>

                <li className="nav-item">
                    <a className="nav-link collapsed" href="#">
                        <i className="bi bi-person"></i>
                        <span>Profile</span>
                    </a>
                </li> */}
            </ul>
        </aside>
    );
}
