import { useState, useEffect, useRef } from 'react';
import {
    VendorSaveService,
    DepartmentGetService,
    VendorDeleteService,
    VendorUpdateService,
    VendorGetOneService
} from '../../../../domain';
import styled from 'styled-components';
import DepartmentTable from './table';
import Swal from 'sweetalert2';

const Form = styled.form`
    opacity: ${(props) => (props.loading ? '0.4' : '1')};
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
`;

const SpinnerWrapper = styled.div`
    display: ${(props) => (props.loading ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`;

export default function DepartmentsView() {
    const [formData, setFormData] = useState({});
    const [loading, setIsLoading] = useState(false);
    const [departmentsList, setDepartmentsList] = useState(null);
    const [tableDetails, setTableDetails] = useState({ size: 10, page: 1 });
    const [search, setSearch] = useState(null);
    const debounceTimeout = useRef(null);
    const [modalState, setModalState] = useState({
        isOpen: false,
        items: null
    });

    const _vendorSave = new VendorSaveService();
    const _departmentGet = new DepartmentGetService();
    const _vendorGetOne = new VendorGetOneService();
    const _vendorDelete = new VendorDeleteService();
    const _vendorUpdate = new VendorUpdateService();

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (formData.length < 6) {
            alert('Please complete the Form to Proceed.');
        }
        console.log('formdata: ', formData);
        setIsLoading(true);

        const response = await _vendorSave.RequestData(formData);
        console.log('vendor create response', response);
        if (!response.data.ok) {
            Swal.fire({
                title: 'Error!',
                text: 'Vendor create failed. ' + response.data.error,
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
            return;
        }
        setFormData({});
        setIsLoading(false);
        loadDepartments();

        Swal.fire({
            title: 'Success!',
            text: 'Vendor create success.',
            icon: 'success',
            confirmButtonText: 'Ok'
        });
    };

    const handleEdit = async (event) => {
        setIsLoading(true);
        const response = await _vendorGetOne.RequestData({
            id: event.VENDOR_ID
        });

        if (!response.data.ok) {
            Swal.fire({
                title: 'Error!',
                text: 'Error retrieving vendor data. ' + response.data.error,
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
            return;
        }
        const vendor = response.data.vendor[0];
        setFormData((e) => ({ ...vendor, isUpdating: true }));
        setIsLoading(false);
    };

    const handleFormUpdate = async (e) => {
        e.preventDefault();

        if (formData.length < 8) {
            alert('Please complete the Form to Proceed.');
        }
        console.log('formdata: ', formData);

        setIsLoading(true);
        const response = await _vendorUpdate.RequestData(formData);

        if (!response.data.ok) {
            Swal.fire({
                title: 'Error!',
                text: 'Vendor update failed. ' + response.data.error,
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
            return;
        }
        Swal.fire({
            title: 'Success!',
            text: 'Vendor update success.',
            icon: 'success',
            confirmButtonText: 'Ok'
        });
        setFormData({});
        setIsLoading(false);
        loadDepartments();
        console.log('vendor update response', response);
    };

    const handleDelete = async (event) => {
        setIsLoading(true);
        const alertResponse = await Swal.fire({
            title: 'Confirm',
            text: "Click 'OK' to proceed.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        });

        if (alertResponse.isConfirmed) {
            const response = await _vendorDelete.RequestData(event);
            setIsLoading(false);
            if (!response.data.ok) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Vendor delete failed. ' + response.data.error,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                });
                setIsLoading(false);
                return;
            }
            Swal.fire({
                title: 'Success!',
                text: 'Vendor delete success.',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            loadDepartments();
            console.log('delete vendor: ', response);
        } else {
            setIsLoading(false);
        }
    };

    const loadDepartments = async (page) => {
        setIsLoading(true);
        const response = await _departmentGet.execute({
            size: 10,
            page: page || 1,
            search: search || ''
        });
        if (!response.data.ok) {
            Swal.fire({
                title: 'Error!',
                text: 'Department list get failed. ' + response.data.error,
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
            setIsLoading(false);
            return;
        }
        setDepartmentsList(response.data.departments);
        setTableDetails({
            ...tableDetails,
            totalPages: response.data.totalPages,
            totalData: response.data.totalData,
            currentPage: response.data.currentPage
        });
        setIsLoading(false);

        console.log('existing departments: ', response);
    };

    const resetForm = async () => {
        setFormData({});
    };

    useEffect(() => {
        if (!departmentsList) {
            loadDepartments();
        }

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            if (departmentsList) {
                loadDepartments();
            }
        }, 1000);

        return () => {
            clearTimeout(debounceTimeout.current);
        };
    }, [search]);

    return (
        <div>
            <section className="section">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Department Details</h5>
                                <div>
                                    <SpinnerWrapper loading={loading ? 1 : 0}>
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                            style={{ width: '30px', height: '30px' }}
                                        ></span>
                                    </SpinnerWrapper>
                                </div>
                                <div>
                                    <Form
                                        onSubmit={formData.isUpdating ? handleFormUpdate : handleFormSubmit}
                                        className="row g-3"
                                        loading={loading ? 1 : 0}
                                        autoComplete="off"
                                        disabled={loading}
                                    >
                                        <div className="col-4">
                                            <label htmlFor="departments" className="form-label">
                                                Parent Department
                                            </label>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                value={formData.parentDepartment ? formData.parentDepartment : ''}
                                                id="departments"
                                                required
                                                onChange={(e) =>
                                                    setFormData((val) => ({ ...val, parentDepartment: e.target.value }))
                                                }
                                            >
                                                <option value="">Select Department</option>
                                                <option value="NO PARENT">NO PARENT</option>
                                                {departmentsList &&
                                                    departmentsList.map((item, index) => {
                                                        return (
                                                            <option
                                                                value={item.DEPARTMENT_NAME}
                                                                key={'department' + index + item.DEPARTMENT_ID}
                                                            >
                                                                {item.DEPARTMENT_NAME}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </div>

                                        <div className="col-4">
                                            <label htmlFor="vendor_name" className="form-label">
                                                Department Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="vendor_name"
                                                required
                                                value={formData.VENDOR_NAME ?? ''}
                                                onChange={(e) =>
                                                    setFormData((val) => ({ ...val, VENDOR_NAME: e.target.value }))
                                                }
                                            />
                                        </div>

                                        <div className="col-12">
                                            <div className="d-flex flex-row">
                                                <div className="me-3">
                                                    <button className="btn btn-primary" type="submit">
                                                        {formData.isUpdating ? 'Update' : 'Submit'}
                                                    </button>
                                                </div>
                                                <div className="">
                                                    <button
                                                        type="reset"
                                                        className="btn btn-secondary"
                                                        onClick={() => resetForm()}
                                                    >
                                                        Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>

                        <DepartmentTable
                            SpinnerWrapper={SpinnerWrapper}
                            formData={formData}
                            loading={loading}
                            departmentsList={departmentsList}
                            tableDetails={tableDetails}
                            setSearch={setSearch}
                            handleEdit={handleEdit}
                            setFormData={setFormData}
                            handleDelete={handleDelete}
                            loadDepartments={loadDepartments}
                            setModalState={setModalState}
                            modalState={modalState}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
}
