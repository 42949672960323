import { formatInTimeZone } from 'date-fns-tz';

function toDateOnly(date) {
    try {
        return formatInTimeZone(date, '', 'MMMM dd, yyyy');
    } catch (error) {
        console.log(error);
        return 'Invalid Date.';
    }
}

export default toDateOnly;
