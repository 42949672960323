import { HandleRecentProcurementsDataGet } from '../../../application';

export default class DataGetService {

    async RequestData(event) {
        const data = await HandleRecentProcurementsDataGet(event);

        return data;
    }
}
