export default function ModalSubBranches({ modalState, setModalState, toReadableDate }) {
    return (
        <div className="modal fade" id="branchmodal" tabIndex="-1">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Branch Details</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row mb-2">
                            <div className="col-2">Branch ID</div>
                            <div className="col-10">{modalState.data && modalState.data.BRANCH_ID}</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-2">Branch Name</div>
                            <div className="col-10">{modalState.data && modalState.data.BRANCH_NAME}</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-2">Branch Address</div>
                            <div className="col-10">{modalState.data && modalState.data.BRANCH_ADDRESS}</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-2">PCC</div>
                            <div className="col-10">{modalState.data && modalState.data.PCC}</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-2">PCC Name</div>
                            <div className="col-10">{modalState.data && modalState.data.PCC_NAME}</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-2">Parent Branch</div>
                            <div className="col-10">
                                {modalState.data && (`${modalState.data.parentBranch[0]?.BRANCH_ID} - ${modalState.data.parentBranch[0]?.BRANCH_NAME}` ?? 'No Parent')}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-2">Created at</div>
                            <div className="col-10">
                                {modalState.data && toReadableDate(modalState.data.CREATED_AT)}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-2">Last updated</div>
                            <div className="col-10">
                                {modalState.data && toReadableDate(modalState.data.UPDATED_AT)}
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-2">Approvers</div>
                            <div className="col-10">
                                {!!modalState?.data?.approvers?.length ? (
                                    modalState.data.approvers.map((item, index) => (
                                        <li key={`app_${index}`}>
                                            {item.USER_ID} - {item.FULLNAME}
                                        </li>
                                    ))
                                ) : (
                                    <i> No available approvers </i>
                                )}
                            </div>
                        </div>

                        <hr style={{ border: 'dashed 2px black' }} />

                        <h5 className="card-title my-0 pt-1 pb-3">Sub-Branches</h5>

                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Branch Name</th>
                                    <th>Approvers</th>
                                    <th>Address</th>
                                    <th>PCC</th>
                                    <th>PCC Name</th>
                                    <th>Created</th>
                                    <th>Last updated</th>
                                </tr>
                            </thead>
                            <tbody>
                                {modalState.data?.subBranch &&
                                    modalState.data.subBranch.map((item, index) => (
                                        <tr key={'itemstbl_' + index}>
                                            <td>{item.BRANCH_ID}</td>
                                            <td>
                                                {modalState.formData?.BRANCH_ID === item.BRANCH_ID ? (
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="branch_name"
                                                        required
                                                        value={
                                                            modalState.formData.BRANCH_NAME
                                                                ? modalState.formData.BRANCH_NAME
                                                                : ''
                                                        }
                                                        onChange={(e) =>
                                                            setModalState((val) => ({
                                                                ...val,
                                                                formData: {
                                                                    ...val.formData,
                                                                    BRANCH_NAME: e.target.value
                                                                }
                                                            }))
                                                        }
                                                    />
                                                ) : (
                                                    item.BRANCH_NAME
                                                )}
                                            </td>
                                            <td>
                                                {!!item?.approvers?.length ? (
                                                    item.approvers.map((item, index) => (
                                                        <li key={`app_${index}`}>{item.USER_ID} - {item.FULLNAME}</li>
                                                    ))
                                                ) : (
                                                    <i> No available approvers </i>
                                                )}
                                            </td>
                                            <td>
                                                {modalState.formData?.BRANCH_ID === item.BRANCH_ID ? (
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="branch_address"
                                                        required
                                                        value={
                                                            modalState.formData.BRANCH_ADDRESS
                                                                ? modalState.formData.BRANCH_ADDRESS
                                                                : ''
                                                        }
                                                        onChange={(e) =>
                                                            setModalState((val) => ({
                                                                ...val,
                                                                formData: {
                                                                    ...val.formData,
                                                                    BRANCH_ADDRESS: e.target.value
                                                                }
                                                            }))
                                                        }
                                                    />
                                                ) : (
                                                    item.BRANCH_ADDRESS
                                                )}
                                            </td>
                                            <td>
                                                {modalState.formData?.BRANCH_ID === item.BRANCH_ID ? (
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="pcc"
                                                        required
                                                        value={modalState.formData.PCC ? modalState.formData.PCC : ''}
                                                        onChange={(e) =>
                                                            setModalState((val) => ({
                                                                ...val,
                                                                formData: {
                                                                    ...val.formData,
                                                                    PCC: e.target.value
                                                                }
                                                            }))
                                                        }
                                                    />
                                                ) : (
                                                    item.PCC
                                                )}
                                            </td>
                                            <td>
                                                {modalState.formData?.BRANCH_ID === item.BRANCH_ID ? (
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="pcc_name"
                                                        required
                                                        value={
                                                            modalState.formData.PCC_NAME
                                                                ? modalState.formData.PCC_NAME
                                                                : ''
                                                        }
                                                        onChange={(e) =>
                                                            setModalState((val) => ({
                                                                ...val,
                                                                formData: {
                                                                    ...val.formData,
                                                                    PCC_NAME: e.target.value
                                                                }
                                                            }))
                                                        }
                                                    />
                                                ) : (
                                                    item.PCC_NAME
                                                )}
                                            </td>
                                            <td>{toReadableDate(item.CREATED_AT)}</td>
                                            <td>{toReadableDate(item.UPDATED_AT)}</td>
                                        </tr>
                                    ))}

                                {modalState.data?.subBranch && !modalState.data.subBranch.length && (
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            No sub-branches yet.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
