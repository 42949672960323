import React from 'react';
import { ToCurrencyFormat, toDateOnly, toReadableDate } from '../../utils';
import { BadgesComponent } from './';

export default function ModalProcurementDetails({ modalState }) {
    console.log('modalState: ', modalState);

    return (
        <div className="modal fade" id="procurementmodal" tabIndex="-1">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Procurement Details</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row mb-2">
                            <div className="col-2">Requested by</div>
                            <div className="col-10">
                                {modalState.data && modalState.data.CREATED_BY_NAME}{' '}
                                {modalState.data && toDateOnly(modalState.data.CREATED_AT)}
                            </div>
                        </div>
                        {/* <div className="row mb-2">
                            <div className="col-2">Approver</div>
                            <div className="col-10">
                                Hector Thomas Javier (MM/DD/YYYY) - Cost Center: H07C <br />
                                Hector Thomas Javier (MM/DD//YYYY) - Cost Center: H07A <br />
                                Hector Thomas Javier (MM/DD/YYYY) - Cost Center: H07B <br />
                            </div>
                        </div> */}
                        <div className="row mb-2">
                            <div className="col-2">Vendor</div>
                            <div className="col-10">{modalState.data && modalState.data.VENDOR_NAME}</div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-2">Quotation/Reference No.</div>
                            <div className="col-10">{modalState.data && modalState.data.REFERENCE_NO}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-2">Description</div>
                            <div className="col-10">{modalState.data && modalState.data.DESCRIPTION}</div>
                        </div>

                        <hr style={{ border: 'dashed 2px black' }} />

                        <h5 className="card-title my-0 pt-1 pb-3">Procurement Items</h5>

                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Item ID</th>
                                    <th>Description</th>
                                    <th>Branch / Cost Center</th>
                                    <th>Status</th>
                                    <th>Remarks</th>
                                    <th>Quantity</th>
                                    <th>Unit</th>
                                    <th>Unit Price</th>
                                    <th width="10%">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {modalState.data &&
                                    modalState.data.items.map((item, index) => (
                                        <tr key={'itemstbl_' + index}>
                                            <td>{index + 1}</td>
                                            <td>{item.ITEM_ID}</td>
                                            <td>{item.ITEM_DESCRIPTION}</td>
                                            <td>{item.BRANCH_NAME ?? item.sub_branch_name}</td>
                                            <td>
                                                <BadgesComponent status={item.APPROVAL_STATUS} />
                                                <span className="badge bg-secondary me-2">
                                                    {item.APPROVED_BY_FULLNAME}
                                                </span>
                                                <span className="badge bg-secondary">
                                                    {item.APPROVAL_DATE && toReadableDate(item.APPROVAL_DATE)}
                                                </span>
                                            </td>
                                            <td>
                                                <i>{item.REMARKS}</i>
                                            </td>
                                            <td width="10%">{item.QUANTITY}</td>
                                            <td>{item.UNIT}</td>
                                            <td width="">{ToCurrencyFormat(item.UNIT_PRICE)}</td>
                                            <td>{ToCurrencyFormat(item.GROSS_AMOUNT)}</td>
                                        </tr>
                                    ))}
                                {modalState.data && modalState.data.items.length > 0 && (
                                    <tr>
                                        <td colSpan="8"></td>
                                        <td colSpan="1">Grand Total:</td>
                                        <td colSpan="1">
                                            <b>
                                                {ToCurrencyFormat(
                                                    modalState.data.items.reduce((accumulator, item) => {
                                                        return parseFloat(accumulator) + parseFloat(item.GROSS_AMOUNT);
                                                    }, 0)
                                                )}
                                            </b>
                                        </td>
                                    </tr>
                                )}

                                {!modalState.data && !modalState.data?.items.length && (
                                    <tr>
                                        <td colSpan="9" className="text-center">
                                            No items yet.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
