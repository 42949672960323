import { UserSaveAdapter } from '../../../../adapter';

export default async function UserSaveUseCase(event) {
    const requestUrl = 'https://nwtbrglvql.execute-api.ap-southeast-1.amazonaws.com/api/procurement/user/save';

    const payload = {
        data: {
            userData: {
                ...event
            },
            signature: ''
        }
    };

    // handle request
    const result = await UserSaveAdapter(requestUrl, JSON.stringify(payload));

    return result;
}
