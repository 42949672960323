import { UserDeleteAdapter } from '../../../../adapter';

export default async function UserDeleteUseCase(event) {
    const { id } = event;

    const requestUrl = 'https://nwtbrglvql.execute-api.ap-southeast-1.amazonaws.com/api/procurement/user/delete';

    const payload = {
        data: {
            userData: {
                id: id
            },
            signature: ''
        }
    };

    // handle request
    const result = await UserDeleteAdapter(requestUrl, JSON.stringify(payload));

    return result;
}
