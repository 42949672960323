import { useState, useEffect } from 'react';
import { ToCurrencyFormat, toDateOnly, toReadableDate } from '../../utils';
import { BadgesComponent } from './';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import { AuthContext } from '../../adapter/context/';
import { ApproveItemService } from '../../domain';

const FormDiv = styled.div`
    opacity: ${(props) => (props.loading ? '0.4' : '1')};
    pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
`;

const SpinnerWrapper = styled.div`
    display: ${(props) => (props.loading ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`;

export default function ModalProcurementApproval({ modalState, loadProcurements, enableButtons = false }) {
    const [formData, setFormData] = useState({});
    const [loading, setIsLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const { user } = AuthContext();

    console.log(modalState.data);

    const _ApproveItemService = new ApproveItemService();

    const initData = () => {
        const recordTouched = modalState?.data?.items.find((item) => item.APPROVAL_STATUS !== 'P');
        if (recordTouched) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }

        console.log('recordTouched', recordTouched);
    };

    const handleApprove = () => {
        setFormData({
            ...formData,
            isApproving: true,
            isDenying: false
        });
    };

    const handleFinalizeApprove = async (status) => {
        setIsLoading(true);

        const buildPayload = {
            transactionId: modalState.data.TRANSACTION_ID,
            createdBy: modalState.data.CREATED_BY,
            procurementFormItemData: modalState.data.items.map((item) => item.ITEM_ID),
            remarks: formData?.remarks ?? '',
            approvalStatus: status,
            approvedBy: user.id
        };

        const response = await _ApproveItemService.RequestData(buildPayload);
        if (!response.data.ok) {
            Swal.fire({
                title: 'Error!',
                text: 'Procurement approval failed. ' + response.data.error,
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
        } else {
            Swal.fire({
                title: 'Success!',
                text: 'Procurement approval success.',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            loadProcurements();
            setFormData({});
        }
        setIsLoading(false);
    };

    const handleCancel = () => {
        setFormData({});
    };

    const handleDeny = async () => {
        setFormData({
            ...formData,
            isApproving: false,
            isDenying: true
        });
    };

    const handleFinalizeDeny = async (status) => {
        const buildPayload = {
            transactionId: modalState.data.TRANSACTION_ID,
            createdBy: modalState.data.CREATED_BY,
            procurementFormItemData: modalState.data.items.map((item) => item.ITEM_ID),
            remarks: formData?.remarks ?? '',
            approvalStatus: status,
            approvedBy: user.id
        };

        const confirmationResponse = await Swal.fire({
            title: 'Confirm',
            text: "Click 'OK' to Deny Procurement Form.",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        });

        if (confirmationResponse.isConfirmed) {
            console.log(buildPayload);

            const response = await _ApproveItemService.RequestData(buildPayload);
            if (!response.data.ok) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Procurement form deny failed. ' + response.data.error,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                });
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: 'Procurement form deny success.',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
                loadProcurements();
                setFormData({});
            }
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        initData();
    }, [modalState]);

    return (
        <div className="modal fade" id="procurementmodal" tabIndex="-1">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Procurement Details</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <SpinnerWrapper loading={loading ? 1 : 0}>
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ width: '30px', height: '30px' }}
                                ></span>
                            </SpinnerWrapper>
                        </div>

                        <FormDiv loading={loading ? 1 : 0}>
                            <div className="row mb-2">
                                <div className="col-2">Requested by</div>
                                <div className="col-10">
                                    {modalState.data && modalState.data.CREATED_BY_NAME}{' '}
                                    {modalState.data && toDateOnly(modalState.data.CREATED_AT)}
                                </div>
                            </div>
                            {/* <div className="row mb-2">
                            <div className="col-2">Approver</div>
                            <div className="col-10">
                                Hector Thomas Javier (MM/DD/YYYY) - Cost Center: H07C <br />
                                Hector Thomas Javier (MM/DD//YYYY) - Cost Center: H07A <br />
                                Hector Thomas Javier (MM/DD/YYYY) - Cost Center: H07B <br />
                            </div>
                        </div> */}
                            <div className="row mb-2">
                                <div className="col-2">Vendor</div>
                                <div className="col-10">{modalState.data && modalState.data.VENDOR_NAME}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-2">Quotation/Reference No.</div>
                                <div className="col-10">{modalState.data && modalState.data.REFERENCE_NO}</div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-2">Description</div>
                                <div className="col-10">{modalState.data && modalState.data.DESCRIPTION}</div>
                            </div>

                            <hr style={{ border: 'dashed 2px black' }} />

                            <h5 className="card-title my-0 pt-1 pb-3">Procurement Items</h5>

                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Item ID</th>
                                        <th>Description</th>
                                        <th>Branch / Cost Center</th>
                                        <th>Status</th>
                                        <th>Remarks</th>
                                        <th>Quantity</th>
                                        <th>Unit</th>
                                        <th>Unit Price</th>
                                        <th width="10%">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {modalState.data &&
                                        modalState.data.items.map((item, index) => (
                                            <tr key={'itemstbl_' + index}>
                                                <td>{index + 1}</td>
                                                <td>{item.ITEM_ID}</td>
                                                <td>{item.ITEM_DESCRIPTION}</td>
                                                <td>{item.BRANCH_NAME ?? item.sub_branch_name}</td>
                                                <td>
                                                    <BadgesComponent
                                                        status={item.APPROVAL_STATUS}
                                                    />
                                                    <span className="badge bg-secondary me-2">
                                                        {item.APPROVED_BY_FULLNAME}
                                                    </span>
                                                    <span className="badge bg-secondary">
                                                        {item.APPROVAL_DATE && toReadableDate(item.APPROVAL_DATE)}
                                                    </span>
                                                </td>
                                                <td>
                                                    <i>{item.REMARKS}</i>
                                                </td>
                                                <td width="10%">{item.QUANTITY}</td>
                                                <td>{item.UNIT}</td>
                                                <td width="">{ToCurrencyFormat(item?.UNIT_PRICE)}</td>
                                                <td>{ToCurrencyFormat(item?.GROSS_AMOUNT)}</td>
                                            </tr>
                                        ))}
                                    {modalState.data && modalState.data.items.length > 0 && (
                                        <tr>
                                            <td colSpan="8"></td>
                                            <td colSpan="1">Grand Total:</td>
                                            <td colSpan="1">
                                                <b>
                                                    {ToCurrencyFormat(
                                                        modalState.data.items.reduce((accumulator, item) => {
                                                            return (
                                                                parseFloat(accumulator) + parseFloat(item.GROSS_AMOUNT)
                                                            );
                                                        }, 0)
                                                    )}
                                                </b>
                                            </td>
                                        </tr>
                                    )}

                                    {!modalState.data && !modalState.data?.items.length && (
                                        <tr>
                                            <td colSpan="9" className="text-center">
                                                No items yet.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <hr style={{ border: 'dashed 2px black' }} />

                            {!!Object.keys(formData).length && (
                                <div>
                                    <h5 className="card-title my-0 pt-1 pb-3">Procurement Approval</h5>
                                    <div className="col-12">
                                        <label htmlFor="vendor_description" className="form-label">
                                            Remarks
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            name="vendor_description"
                                            value={formData.remarks ?? ''}
                                            required
                                            onChange={(e) =>
                                                setFormData((val) => ({ ...val, remarks: e.target.value }))
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </FormDiv>
                    </div>
                    <div className="modal-footer justify-content-between">
                        {enableButtons && !disableButton ? (
                            <div>
                                {!formData?.isDenying && (
                                    <button
                                        type="button"
                                        className={`btn ${formData?.isApproving ? 'btn-success' : 'btn-primary'} me-2`}
                                        title="Approve"
                                        onClick={() => {
                                            if (formData?.isApproving) {
                                                handleFinalizeApprove('A');
                                            } else {
                                                handleApprove();
                                            }
                                        }}
                                    >
                                        <i className="bi bi-check-circle"> </i>
                                        {formData.isApproving ? 'Finalize Approval' : 'Approve'}
                                    </button>
                                )}

                                {!formData?.isApproving && (
                                    <button
                                        type="button"
                                        className={`btn ${formData?.isDenying ? 'btn-danger' : 'btn-warning'} me-2`}
                                        title="Deny"
                                        onClick={() => {
                                            if (formData?.isDenying) {
                                                handleFinalizeDeny('D');
                                            } else {
                                                handleDeny();
                                            }
                                        }}
                                    >
                                        <i className="bi bi-file-earmark-x"> </i>
                                        {formData.isDenying ? 'Finalize Deny' : 'Deny'}
                                    </button>
                                )}
                                {!!Object.keys(formData).length && (
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        title="Deny"
                                        onClick={() => handleCancel()}
                                    >
                                        Cancel
                                    </button>
                                )}
                            </div>
                        ) : (
                            <div></div>
                        )}

                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={() => handleCancel({})}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
