import { BranchSaveAdapter } from '../../../../adapter';

export default async function BranchSaveUseCase(event) {
    const { BRANCH_NAME, BRANCH_ADDRESS, PCC, PCC_NAME, PARENT_BRANCH_ID, IS_SUB_BRANCH } = event;

    const requestUrl = 'https://nwtbrglvql.execute-api.ap-southeast-1.amazonaws.com/api/procurement/branch/save';

    const payload = {
        data: {
            branchData: {
                branch_name: BRANCH_NAME,
                branch_address: BRANCH_ADDRESS,
                pcc: PCC,
                pcc_name: PCC_NAME,
                parent_branch_id: PARENT_BRANCH_ID,
                is_sub_branch: IS_SUB_BRANCH
            },
            signature: ''
        }
    };

    const result = await BranchSaveAdapter(requestUrl, JSON.stringify(payload));
    return result;
}
