import { Login } from '../../../adapter';
import { ValidateUser } from '../../../domain';
import { DecryptTokenRequest } from '../../../infrastructure';

export default async function handleLogin(username, password) {
    // sanitize data
    const _username = username.trim();
    const _password = password.trim();

    // validate data
    ValidateUser(_username, _password);

    // handle request
    const loginResponse = await Login(_username, _password);
    console.log('loginResponse: ', loginResponse);

    if (!loginResponse.data.ok) {
        throw new Error(loginResponse.data.error || 'Unknown error');
    }

    // call the login token decryptor
    const decrypted = await DecryptTokenRequest({ token: loginResponse.data.token });
    console.log('user session: ', decrypted);

    const data = decrypted;

    return data;
}
