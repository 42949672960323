import React from 'react';

export default function Pagination({ totalData, currentPage, totalPages, onPageChange }) {
    const recordsPerPage = 10;
    const startRecord = totalData > 0 ? (currentPage - 1) * recordsPerPage + 1 : 0;
    const endRecord = Math.min(currentPage * recordsPerPage, totalData);

    const handleClick = (page) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const renderPageNumbers = () => {
        let pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handleClick(i)}>
                        {i}
                    </button>
                </li>
            );
        }
        return pages;
    };

    return (
        <div className="row justify-content-between">
            <div className="col">
                Showing {startRecord} to {endRecord || 0} of {totalData || 0} records
            </div>
            <div className="col">
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end py-0 my-0">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button
                                className="page-link"
                                aria-label="Previous"
                                onClick={() => handleClick(currentPage - 1)}
                            >
                                <span aria-hidden="true">&laquo;</span>
                            </button>
                        </li>
                        {renderPageNumbers()}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button
                                className="page-link"
                                aria-label="Next"
                                onClick={() => handleClick(currentPage + 1)}
                            >
                                <span aria-hidden="true">&raquo;</span>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}
