import { CoreRequest } from '../../../../infrastructure';

export default async function UserDeleteAdapter(requestUrl, content = {}) {
    const execute = async (requestUrl, body = {}) => {
        const req = {
            requestUrl,
            body
        };
        const response = await CoreRequest(req);

        return response.json();
    };

    try {
        return execute(requestUrl, content);
    } catch (error) {
        console.error('Error', error);
        throw new Error(`failed: ${error}`);
    }
}
