import { BranchGetAllUseCase, VendorGetAllUseCase, DepartmentGetAllUseCase, ApproverGetUseCase } from '../../../application';

async function FormDataGet() {
    const [branches, vendors, approvers, departments] = await Promise.all([
        BranchGetAllUseCase(),
        VendorGetAllUseCase(),
        ApproverGetUseCase(),
        DepartmentGetAllUseCase()
    ]);

    return {
        branches: branches.data.branch,
        vendors: vendors.data.vendor,
        approvers: approvers.data.approvers,
        departments: departments.data.departments
    };
}

export default FormDataGet;
