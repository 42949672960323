const cryptoJs = require('crypto-js');
const cryptoKey = "U2FsdGVkX1+JPpuH6kBmbEAxOGa5N6AuyCHF4LHhcSA=";

function Encrypt(data) {
    if (data === undefined) {
        data = null;
    }

    return cryptoJs.AES.encrypt(JSON.stringify(data), cryptoKey).toString();
}

module.exports = Encrypt;