import { PaymentRequestSaveAdapter } from '../../../adapter';

export default async function PaymentRequestSaveUseCase(event) {
    const requestUrl =
        'https://nwtbrglvql.execute-api.ap-southeast-1.amazonaws.com/api/procurement/payment-request/save';

    const payload = {
        ...event
    };

    const result = await PaymentRequestSaveAdapter(requestUrl, JSON.stringify(payload));
    return result;
}
