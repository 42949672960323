import { VendorGetAdapter } from '../../../../adapter';

export default async function VendorGetUseCase(event) {
    const requestUrl = 'https://nwtbrglvql.execute-api.ap-southeast-1.amazonaws.com/api/procurement/vendor/get';

    const payload = {
        data: {
            vendorData: {
                ...event
            },
            signature: ''
        }
    };

    const result = await VendorGetAdapter(requestUrl, JSON.stringify(payload));
    return result;
}
